import { DeleteForever, QueuePlayNext } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import Footer from "../../components/footer";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars";
import Title from "../../components/title-name";
import { browserHistory } from "../../helpers/history";
import Api from "../../services/api";
import { Overlay } from "../../styles/global";
import { Styles } from "../../styles/material-styles";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import articles from "src/redux/reducers/articles";
import { PapiroConsole } from "src/utils/papiroConsole";

class ArticlesRegistration extends Component {
  constructor(props) {
    super(props);
    PapiroConsole.log("Props de articles aqui!");
    PapiroConsole.log(this.props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (
      userSession == null ||
      userSession.user == null ||
      userSession.user.userrole == null
    ) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format("YYYY/MM/DD HH:mm:ss"),
      title: "",
      subtitle: "",
      readingtime: 0,
      content: "",
      sectionid: 0,
    };

    this.state = {
      item: this.initialState,
      files: [],
      ArticlesAttachments: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      sectionList: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.validateImagesAtachments = this.validateAtachments.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getSections();
  }

  async getSections() {
    this.setState({ loading: true });

    const url = `articles-section/get-sections-name/`;

    var result = await Api.get(url)
      .then((rs) => rs.data)
      .catch((err) => err);

    if (result.success) {
      this.setState({ loading: false, sectionList: result.data });
    } else {
      const intl = this.props.intl;

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
          result.data.response &&
          result.data.response.data &&
          result.data.response.data.errors &&
          result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }

    return result.data;
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = { ...this.state.item };

    let payload = new FormData();

    for (let key in data) {
      if (key == "sectionid") payload.append(key, data[key].id);
      else payload.append(key, data[key]);
    }

    let files = this.state.files ?? [];

    if (files?.length > 3) {
      files = files.slice(0, 3);
    }

    PapiroConsole.log("data do submit");
    PapiroConsole.log(data);

    files.forEach((file) => payload.append(`FilesUpload`, file));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/articles", payload, config)

      .then((result) => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState,
            files: [],
            sectionid: 0,
          });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));

    this.setState({ loading: false });
  }

  handleChangeEditor(value, name) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value || "",
      },
    }));
  }

  /* INÍCIO FLUXO DE ANEXOS */

  validateAtachments = (files) => {
    this.setState({
      files: files,
    });
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  /* FIM FLUXO DE ANEXOS */

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`;
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(
            classes.content,
            {
              [classes.contentShift]: headerMenu.open,
            },
            classes.actionsContent,
            {
              [classes.actionscontentShift]: actionsHeaderMenu.open,
            }
          )}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="add.item" />} <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="application/json"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{ maxLength: 250 }}
                      fullWidth
                      label={intl.formatMessage({ id: "title.articles" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="title"
                      onChange={this.handleInputChange}
                      value={item.title}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{ maxLength: 250 }}
                      fullWidth
                      label={intl.formatMessage({ id: "subtitle.articles" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="subtitle"
                      onChange={this.handleInputChange}
                      value={item.subtitle}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      type="time"
                      inputProps={{ maxLength: 250 }}
                      fullWidth
                      label={intl.formatMessage({ id: "reading.time" })}
                      autoComplete="fname"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      name="readingtime"
                      onChange={this.handleInputChange}
                      value={item.readingtime}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "section.name" })}
                      name="sectionid"
                      options={this.state.sectionList}
                      stateName="sectionid"
                      changeSelect={this.changeValues}
                      selected={this.state.item.sectionid}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="content.articles" />
                    </Typography>
                    <div
                      style={{
                        resize: "vertical",
                        overflow: "auto",
                        height: "250px",
                        marginBottom: "24px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <ReactQuill
                        name="content"
                        value={item.content}
                        style={{ height: "100%" }}
                        modules={this.reactQuillModules}
                        formats={this.reactQuillFormats}
                        fullWidth
                        variant="outlined"
                        id="description"
                        onChange={(value) =>
                          this.handleChangeEditor(value, "content")
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} sm={12}>
                      <section style={{ width: "100%" }}>
                        <div
                          className={classes.dropzoneArea}
                          style={{ minHeight: "70px", marginTop: "5px " }}
                        >
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.validateAtachments([
                                ...this.state.files,
                                ...acceptedFiles,
                              ])
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <AttachFileIcon />
                                <Typography
                                  component="span"
                                  fontWeight="bold"
                                  fontSize={16}
                                  color="gray"
                                >
                                  <FormattedMessage id="drag.and.drop.article.attachment" />
                                </Typography>
                              </div>
                            )}
                          </Dropzone>
                          {this.state.files.map((file) => {
                            return (
                              <div
                                className={classes.dropzoneItem}
                                key={file?.name}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {file?.name}
                                <DeleteForever
                                  className={classes.deleteItem}
                                  onClick={() => {
                                    this.removeItem(file);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </section>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      marginTop: 15,
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 15,
                    }}
                  >
                    <Grid
                      container
                      justify="flex-end"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Grid item xs={12} sm={4}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
ArticlesRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(Styles)(ArticlesRegistration))
);
