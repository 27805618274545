export default function breakreasons(state = [], action) {
  switch (action.type) {
    case "ADD_CURRENT":
      return {
        ...state,
        breakreasons: action.breakreasons,
      };
    default:
      return state;
  }
}
