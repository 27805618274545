import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import Rating from '@mui/material/Rating';
import CustomizedSnackbars from "../../components/material-snackbars";
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ReactHtmlParser from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderToString, renderToStaticMarkup } from "react-dom/server"
import { injectIntl } from "react-intl";
import { PapiroConsole } from "../../utils/papiroConsole";
import MEFeedbackHeader from "../../components/me-feedback-images/header";
import MEPhoneIcon from "../../components/me-feedback-images/phone-icon";
import MEUserIcon from "../../components/me-feedback-images/user-icon";
import MEDescriptionIcon from "../../components/me-feedback-images/description-icon";
import MEInternetIcon from "../../components/me-feedback-images/internet-icon";
import MELogo from "../../components/me-feedback-images/me-logo";
import MELikeIcon from "../../components/me-feedback-images/like-icon";
import MEUnlikeIcon from "../../components/me-feedback-images/unlike-icon";
import * as SatisfactionSurveyService from '../../services/satisfactionsurvey.service';
import ThumbsUpOrDown from "./components/thumbs-up-or-down";
import RatingScale from "./components/rating-scale";
import RatingScaleOldLayout from "./components/rating-scale-old-layout";
import ThumbsUpOrDownOldLayout from "./components/thumbs-up-or-down-old-layout";
import StarScale from "./components/star-scale";
import clsx from "clsx";
import './styles.css'
import DialogClientFeedbackSuccess from "../../components/dialog-client-feedback-success";
import { ThumbUpSharp } from "@mui/icons-material";
import TODOFeedbackHeader from "../../components/2do-feedback-images/header";

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.ButtonExpired = null
    this.state = {
      isMobile: false,
      feedback: {
        description: "",
        accessToken: "",
        feedbackBo: "",
        DisabledCallRejectionByRequester: false,
        title: null,
        technicalName: null,
        userName: null,
        calledId: null,
        calledDescription: "",
        acesstokenassigned: "",
        HirerId: 0,


      },
      dialogSuccessFeedback: false,
      firstQuestionValue: null,
      colorsZeroToTen: [
        '#EC475C',
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
        '#0eae9c'

      ],
      colorsOneToTen: [
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
        '#0eae9c'

      ],
      colorsOneToFive: [
        '#EC475C',
        '#FB8A31',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
      ],
      feedbackProvided: false,
      showContent: false,
      feedbackError: false,
      ExpiredLink: false,
      newLayout: false,
      calledId: null,
      buttonRender: false,
      loading: false,
      disabledButtons: true,
      openNotification: false,
      notificationVariant: "error",
      disabledResendButton: false,
      notificationMessage: "",
      starRating: null,
      ratingVisible: true,
      showPresentedSolutionQuestion: true,
      flagNotRejectedCalled: false,
      satisfactionSurveyId: 0,
      satisfactionSurveyQuestions: [],
      showCalledRejection: true,
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectedFeedback = this.selectedFeedback.bind(this);
    this.insertClassification = this.insertClassification.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.changeValuesSurveyQuestions = this.changeValuesSurveyQuestions.bind(this)
    this.updatePredicate = this.updatePredicate.bind(this)
    this.handleCloseDialogSuccessFeedback = this.handleCloseDialogSuccessFeedback.bind(this);

    this.handleOpenDialogSuccessFeedback = this.handleOpenDialogSuccessFeedback.bind(this);
  }

  selectedFeedback(event) {
    document.getElementById('ThumbUp').style.color = 'black';
    document.getElementById('ThumbDown').style.color = 'black';

    document.getElementById('ThumbUp').classList.remove("active");
    document.getElementById('ThumbDown').classList.remove("active");

    if (event.currentTarget.id == 'ThumbDown') {
      event.currentTarget.style.color = 'red';
      document.getElementById('ThumbDown').classList.add("active");

      this.setState({ ratingVisible: false });

    } else {
      event.currentTarget.style.color = 'green';
      document.getElementById('ThumbUp').classList.add("active");

      this.setState({ ratingVisible: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    console.log("entrei no did mount tanto no enter quanto pelo email")
    console.log(this.props)
    if (this.props.match.params.token) {
      await this.getFeedback(this.props.match.params.token);

    }

    if (!this.state.feedback.DisabledCallRejectionByRequester) {



      if (this.props.match.params.type == 'positive') {

        this.setState({ ratingVisible: true })

      } else if (this.props.match.params.type == 'negative') {
        this.setState({ ratingVisible: false })
      }

    }
    else if (this.props.match.params.type == 'notrejected') {


      this.setState({ showPresentedSolutionQuestion: false, flagNotRejectedCalled: true });


    }

    this.setState({ newLayout: true });



    // Lógica para layout novo

    /*if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15)) {
      PapiroConsole.log("entrei aqui 123")
      if (!this.state.feedback.DisabledCallRejectionByRequester) {



        if (this.props.match.params.type == 'positive') {

          this.setState({ ratingVisible: true })

        } else if (this.props.match.params.type == 'negative') {
          this.setState({ ratingVisible: false })
        }

      }
      else if (this.props.match.params.type == 'notrejected') {


        this.setState({ showPresentedSolutionQuestion: false, flagNotRejectedCalled: true });


      }

      this.setState({ newLayout: true });

    }
    // Lógica para layout antigo 
    else {
      if (!this.state.feedback.DisabledCallRejectionByRequester) {

        if (document.getElementById('ThumbUp') != null) {
          document.getElementById('ThumbUp').style.color = 'black';
        }

        if (document.getElementById('ThumbDown') != null) {
          document.getElementById('ThumbDown').style.color = 'black';
        }


        if (this.props.match.params.type == 'positive') {
          if (document.getElementById('ThumbUp') != null) {
            document.getElementById('ThumbUp').style.color = 'green';
            document.getElementById('ThumbUp').classList.add("active");
          }

        } else if (this.props.match.params.type == 'negative') {
          this.setState({ ratingVisible: false });
          if (document.getElementById('ThumbDown') != null) {

            document.getElementById('ThumbDown').style.color = 'red';
            document.getElementById('ThumbDown').classList.add("active");
          }

        }
        else if (this.props.match.params.type == 'notrejected') {

          if (document.getElementById('ThumbUp') != null && document.getElementById('ThumbDown') != null) {

            let ThumbUp = document.getElementById('ThumbUp');
            let ThumbDown = document.getElementById('ThumbDown');
            ThumbDown.style.display = "none";
            ThumbUp.style.display = "none";
            this.setState({ showPresentedSolutionQuestion: false, flagNotRejectedCalled: true });
          }


        }
      }
    }*/



    let starRating = 0;
    if (this.props.match.params.stars != null && !isNaN(this.props.match.params.stars) && this.props.match.params.stars > 0) {
      this.setState({ starRating: this.props.match.params.stars })
      starRating = this.props.match.params.stars
    }
    PapiroConsole.log(this.props.match)
    PapiroConsole.log("this.props.match")
    if (this.props.match.params.fqa != null && !isNaN(this.props.match.params.fqa)) {

      let fqa = this.props.match.params.fqa
      PapiroConsole.log("fqa")
      PapiroConsole.log(fqa)
      this.setState({ firstQuestionValue: this.props.match.params.fqa })
    }

    PapiroConsole.log("entrei aqui 12")
    await this.getSatisfactionSurveyByHirerIdAndCalledId(starRating);
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    this.setState({ showContent: true });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  handleCloseDialogSuccessFeedback() {
    this.setState({ dialogSuccessFeedback: false });
  }

  handleOpenDialogSuccessFeedback() {
    this.setState({ dialogSuccessFeedback: true });
  }



  updatePredicate = () => {
    this.setState({ isMobile: window.innerWidth < 959.9 });
  };

  async getFeedback(token) {
    this.setState({ loading: true });

    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };

    PapiroConsole.log("this.props feedback")
    PapiroConsole.log(this.props)
    if (this.props.location.search && this.props.location.search.length > 20) {
      config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
    }

    await Api.get(`/feedback/${token}`, config)
      .then(result => {
        if (result.data.success) {
          const value = result.data.data.accesstoken;

          let ordenedAccompaniments = result.data.data.accompaniment ? result.data.data.accompaniment.sort((a, b) => b.id - a.id) : null

          let lastAccomp = ordenedAccompaniments ? ordenedAccompaniments[0].description : result.data.data.called.accompaniments[result.data.data.called.accompaniments.length - 1].description

          if (lastAccomp.trim().startsWith("<p> &lt;h")) {
            lastAccomp = renderToStaticMarkup(lastAccomp)
          } else if (lastAccomp.trim().startsWith("<div style=\"") || lastAccomp.trim().startsWith("<div dir=") || lastAccomp.trim().startsWith("<p>") || lastAccomp.trim().startsWith("<h1>") || lastAccomp.trim().startsWith("<h2>") || lastAccomp.trim().startsWith("<h3>")) {

          } else {
            lastAccomp = renderToStaticMarkup(lastAccomp)
          }

          console.log("=== LastAccomp ===")
          console.log(result)

          this.setState(prevState => ({
            loading: false,

            disabledButtons: false,
            feedback: {
              ...prevState.feedback,
              calledDescription: result.data.data.description ? result.data.data.description : '',
              calledId: result.data.data.calledid,
              title: result.data.data.calledTitle ? result.data.data.calledTitle : '',
              technicalName: result.data.data.technicalname,
              feedbackBo: result.data.data.feedbackbo,
              lastaccompainiment: lastAccomp,
              DisabledCallRejectionByRequester: result.data.data.disabledcallrejectionbyrequester,
              'accesstoken': value,
              HirerId: result.data.data.hirerid
            },
            flagNotRejectedCalled: result.data.data.disabledcallrejectionbyrequester ? true : false
          }));
        }
        else {

          this.setState({
            disabledButtons: true,
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        PapiroConsole.log("entrei nesse erro")
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, disabledButtons: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }),
          feedbackError: true
        });

        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {

          if (err.response.data.errors[0] == intl.formatMessage({ id: "nps.email.rating.link.expired.feedback" })) {

            this.addButtonExpiredToken()
          }
          else {
            this.setState({ feedbackError: true });
            if (err.response.data.errors[0] == intl.formatMessage({ id: "feedback.provided" })) {

              this.setState({ feedbackProvided: true });
            }
          }


          this.setState({ feedbackError: true });


          /*if(err.response.data.errors[0] == intl.formatMessage({id:"feedback.provided"})){
          
            this.setState({ feedbackError: true }); 
          }

          if(err.response.data.errors[0] == intl.formatMessage({id:"token.not.found"})){
          
            this.setState({ feedbackError: true });
        }*/
        }
        // document.getElementById('submit').classList.add("Mui-disabled");
      });
    this.setState({ loading: false });
  };

  async getSatisfactionSurveyByHirerIdAndCalledId() {

    let hirerId = this.state.feedback.HirerId;
    let calledId = this.state.feedback.calledId
    if (hirerId && calledId) {
      this.setState({ loading: true });
      var result = await SatisfactionSurveyService.getByHirerIdAndCalledId(hirerId, calledId);
      //console.log(result.data);
      if (result.success) {


        let satisfactionsurveyquestions = result.data.satisfactionsurveyquestions
        let showCalledRejection = true
        if (satisfactionsurveyquestions && satisfactionsurveyquestions.length > 0) {
          //ordenação por id 
          satisfactionsurveyquestions = satisfactionsurveyquestions.sort((a, b) => a.id - b.id)

          // const satisfactionSurveyQuestionsList = satisfactionsurveyquestions.map((item) => ({
          //   id: item.id,
          //   name: item.name,
          //   indcsatisfiedserviceindex: item.indcsatisfiedserviceindex,
          //   satisfactionsurveyquestionstypeid: item.satisfactionsurveyquestionstypeid,
          //   response: item.indcsatisfiedserviceindex && this.state.starRating ? this.state.starRating : null

          // }));

          const satisfactionSurveyQuestionsList = satisfactionsurveyquestions.map((item) => {
            if (item.calledresolvedindicator == true) {
              showCalledRejection = false
            }
            return {
              id: item.id,
              name: item.name,
              indcsatisfiedserviceindex: item.indcsatisfiedserviceindex,
              satisfactionsurveyquestionstypeid: item.satisfactionsurveyquestionstypeid,
              response: item.indcsatisfiedserviceindex && this.state.starRating ? this.state.starRating : null,
              calledresolvedindicator: item.calledresolvedindicator
            }
          });

          this.setState({ loading: false, satisfactionSurveyId: result.data.id, satisfactionSurveyQuestions: satisfactionSurveyQuestionsList, showCalledRejection: showCalledRejection  });
        }
        this.setState({ loading: false });

      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    }

  };

  componentDidUpdate(_, prevState) { }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      feedback: {
        ...prevState.feedback,
        [name]: value
      }
    }));
  }

  addButtonExpiredToken() {
    const intl = this.props.intl;

    const { classes } = this.props;
    if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15)) {
      this.ButtonExpired =
        <Button
          onClick={this.resendEmail}

          variant="contained"
          color="primary"
          className={classes.submit}
          style={{ width: "200px", backgroundColor: '#09316F', fontWeight: 'bold', fontSize: '1em', borderRadius: '20px' }} >
          {intl.formatMessage({ id: "resend.feedback.link" })}
        </Button>
    }
    else {
      this.ButtonExpired =
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.resendEmail}
          style={{ width: "300px", marginRight: '16px' }}

        >
          {intl.formatMessage({ id: "resend.feedback.link" })}
        </Button>
    }


    this.setState({ ExpiredLink: true, loading: false });



  }

  async resendEmail() {
    const intl = this.props.intl;
    this.setState({ loading: true });

    console.log("entrei aqui")

    Api.put(`feedback/resendFeedback/${this.props.match.params.token}`)
      .then(result => {
        if (result.data.success) {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "nps.email.rating.feedback.link.sent" }),
            disabledResendButton: true
          });


        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          disabledResendButton: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

        });
      })




  }

  insertClassification(feedback) {
    const intl = this.props.intl;

    const { starRating } = this.state;
    const { history } = this.props;

    Api.post("/calledclassifications/external", { ObservationAssigned: feedback.description, NoteAssigned: starRating, CalledId: feedback.calledid })
      .then(result => {
        // console.log(result)
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "feedback.assigned.feedback.received" })
          });
          setTimeout(function () { history.push("/"); }, 1000);
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "feedback.assigned.error" })
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      })

    this.setState({ loading: false })
  }

  changeValuesSurveyQuestions(index, value, indcsatisfiedserviceindex) {

    PapiroConsole.log("index")
    PapiroConsole.log(index)


    const values = [...this.state.satisfactionSurveyQuestions];
    values[index]['response'] = value;
    if (values[index]['calledresolvedindicator'] == true && (!this.state.feedback.DisabledCallRejectionByRequester)) {
      if (value == true) {
        this.setState({ ratingVisible: true })
      } else {
        this.setState({ ratingVisible: false })
      }
    }
    this.setState({
      satisfactionSurveyQuestions: values,
      starRating: indcsatisfiedserviceindex ? value : this.state.starRating
    });




  };

  checkValidations() {
    const intl = this.props.intl;
    let validation = true;

    const { starRating } = this.state;

    if ((starRating == undefined || starRating == null) && this.state.ratingVisible) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "feedback.assigned.grade.attendance" }),
      });
      validation = false

    }
    else if (this.state.satisfactionSurveyQuestions && this.state.satisfactionSurveyQuestions.length > 0) {

      this.state.satisfactionSurveyQuestions.forEach(element => {
        if (element.response == null) {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "answer.all.questions" }),
          });
          validation = false

        }

      });

    }

    return validation;
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });

    const { starRating } = this.state;
    var feedbackBoValue;

    let validations = this.checkValidations()
    if (validations) {
      //layout novo 
      /*if (this.state.newLayout) {
        PapiroConsole.log("entrei aqui")
        PapiroConsole.log(this.state.ratingVisible)
        PapiroConsole.log("this.state.ratingVisible")
        if (this.state.ratingVisible == true) {
          feedbackBoValue = "1"
        }
        else {
          feedbackBoValue = "2"
        }
      }*/
      //layout antigo
      /*else {


        var feedbackBoId = ''
        if (this.state.flagNotRejectedCalled) {
          feedbackBoId = 'ThumbUp'
        }
        else {
          if (document.getElementsByClassName('active') != null) {
            if (document.getElementsByClassName('active')[0] != null) {

              feedbackBoId = document.getElementsByClassName('active')[0].id;

            }

          }

        }

        if (feedbackBoId == 'ThumbUp') {
          feedbackBoValue = "1"; //Mão pra cima e aceitou o atendimento
        } else {
          feedbackBoValue = "2" //Mão pra baixo e recusou o atendimento, feedbackbo serve pra isso
        }

      }*/


      PapiroConsole.log("entrei aqui")
      PapiroConsole.log(this.state.ratingVisible)
      PapiroConsole.log("this.state.ratingVisible")
      if (this.state.ratingVisible == true) {
        feedbackBoValue = "1"
      }
      else {
        feedbackBoValue = "2"
      }


      const { description, accesstoken } = this.state.feedback;
      const { history } = this.props;

      if (feedbackBoValue == '2' && description == "") {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({ id: "feedback.assigned.called.reopen.justification" })
        });
      } else {

        let config = {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        };

        if (this.props.location.search && this.props.location.search.length > 20) {
          config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
        }

        //origem email por padrão 
        var originOfSurveyResponse = 4;
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.source && this.props.match.params.source == "whatsapp"  ){
          //origem whatsapp
          originOfSurveyResponse = 5;
        }

        Api.put("/feedback", { Description: description, FeedbackBo: feedbackBoValue, AccessToken: accesstoken, Rating: starRating, ApplicationOriginId: 2, SatisfactionSurveyQuestions: this.state.satisfactionSurveyQuestions, SatisfactionSurveyId: this.state.satisfactionSurveyId, OriginOfSurveyResponse : originOfSurveyResponse }, config)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "feedback.assigned.feedback.received" }),
                disabledButtons: true
              });
              this.handleOpenDialogSuccessFeedback()
              setTimeout(function () { history.push("/"); }, 8000);
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {

              if (err.response.data.errors[0] == intl.formatMessage({ id: "nps.email.rating.link.expired.feedback" })) {

                this.addButtonExpiredToken()
              }
            }
          })
      }
    }





  };
  closeNotification() {
    this.setState({ openNotification: false });
  }
  render() {

    PapiroConsole.log(this.state.dialogSuccessFeedback)
    PapiroConsole.log("this.state.dialogSuccessFeedback")
    PapiroConsole.log(this.state.ratingVisible)
    PapiroConsole.log("this.state.ratingVisible")
    PapiroConsole.log(this.state.starRating)
    PapiroConsole.log("this.state.starRating")
    PapiroConsole.log(this.state.satisfactionSurveyQuestions)
    PapiroConsole.log("this.state.satisfactionSurveyQuestions")
    const intl = this.props.intl;

    const styles = {

      regularFont: {
        color: "black",
        fontWeight: "700",
        margin: "0px",
        padding: "0px",
      },

      grayFont: {
        margin: "0px",
        padding: "0px",
        display: 'flex',
        color: "#909497 ",
        fontSize: "15px",
        fontWeight: "500",
      },

      alignMiddle: {

        verticalAlign: "middle"
      },

    }
    const { classes } = this.props;
    const { feedback } = this.state;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }
    //&& (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15))
    if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15)) {
      const { isMobile } = this.state;
      return (
        <Container component="main" maxWidth="md" className={isMobile ? "feedback-main-container" : ""} >
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          {/* CABEÇALHO ME FIXO*/}
          <Grid container spacing={2} style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: 0 }}>
              <MEFeedbackHeader style={{ width: '100%', maxWidth: '100%' }} />
              <div style={{ backgroundColor: '#09316F', color: '#FFF', padding: '2% 10% 1% 5%', display: 'inline-block', borderTopRightRadius: 150, borderBottomRightRadius: 150, position: 'relative', left: 0, bottom: '15%' }} className={isMobile ? "feedback-bg-help-text" : ""}>
                <h4 style={{ display: 'inline-block', margin: 0 }}>
                 
               
                    <strong>Ajude a melhorar nosso atendimento!</strong>
                  


                </h4>
                <br />
               
                  <h5 style={{ display: 'inline-block', margin: 0 }}>Sua opinião é muito importante!</h5>
                

              </div>
            </Grid>

            {/* CASO NÃO OCORRA NENHUM ERRO - EXEMPLO : TOKEN EXPIRADO, LINK DE EXPIRAÇÃO ETC */}
            {!this.state.feedbackError && this.state.showContent &&
              <>
                {/*CONTEUDO ME - CHAMADO, RESPONSÁVEL, DESCRIÇÃO */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }} >
                  <Grid container spacing={1} style={{ paddingLeft: '10%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }} >
                      <MEPhoneIcon style={{ width: 25 }} />&nbsp;&nbsp;&nbsp;&nbsp;Chamado {this.state.feedback.calledId}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <MEUserIcon style={{ width: 25 }} />&nbsp;&nbsp;&nbsp;&nbsp;<strong>Responsável pelo chamado:</strong>&nbsp;{this.state.feedback.technicalName}
                    </Grid>

                    {Api.getSassHirerId() == 15  ?
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <MEDescriptionIcon style={{ width: 25 }} />&nbsp;&nbsp;&nbsp;&nbsp;<strong>Solução:</strong>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: '8%', color: '#BBB' }}>
                        {ReactHtmlParser(this.state.feedback.lastaccompainiment)}
                        </Grid>
                      </Grid>
                      :
                     <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <MEDescriptionIcon style={{ width: 25 }} />&nbsp;&nbsp;&nbsp;&nbsp;<strong>Descrição:</strong>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: '8%', color: '#BBB' }}>
                        {ReactHtmlParser(this.state.feedback.calledDescription)}
                        </Grid>
                      </Grid>
                    }
                    
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '8%', padding: '1% 0', backgroundColor: '#09316F', display: 'flex', color: '#FFF', justifyContent: 'center' }} className={isMobile ? "feedback-bg-answers-text" : ""} >
                  <h5><strong>Responda as perguntas abaixo, leva menos de 1 minuto.</strong></h5>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={isMobile ? "feedback-form" : ""} >
                  <Grid container spacing={1} style={{ paddingLeft: '10%' }}>
                    {!this.state.feedback.DisabledCallRejectionByRequester && this.state.showCalledRejection &&
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={5} xl={4} >
                          <p style={{ color: '#09316F' }}><strong>01.</strong>Sua solicitação foi resolvida?</p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} >
                          <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === true ? '#CCC' : '#EEE', borderRight: '1px solid #CCC', cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({ ratingVisible: true })
                              }}>
                              <p style={{ color: '#595959', paddingRight: '10%' }}><strong>SIM</strong></p>
                              <MELikeIcon style={{ width: 30 }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === false ? '#CCC' : '#EEE', borderLeft: '1px solid #CCC', cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({ ratingVisible: false })
                              }}>
                              <p style={{ color: '#595959', paddingRight: '10%' }}><strong>NÃO</strong></p>
                              <MEUnlikeIcon style={{ width: 30 }} />
                            </div>
                          </div>
                        </Grid>
                      </>
                    }
                    {this.state.satisfactionSurveyQuestions.length && this.state.satisfactionSurveyQuestions.length > 0 ?

                      <>
                        {this.state.satisfactionSurveyQuestions.map((item, index) => (
                          <>
                            {item.satisfactionsurveyquestionstypeid == 1 &&

                              <ThumbsUpOrDown fqa={this.state.firstQuestionValue} newlayout={true} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} index={index} title={item.name} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } />

                            }
                            {item.satisfactionsurveyquestionstypeid == 2 &&

                              <RatingScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={0} end={10} colors={this.state.colorsZeroToTen} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } />

                            }
                            {item.satisfactionsurveyquestionstypeid == 3 &&

                              <RatingScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={1} end={10} colors={this.state.colorsOneToTen} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } />

                            }
                            {item.satisfactionsurveyquestionstypeid == 4 &&

                              <StarScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} end={9} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } />

                            }
                            {item.satisfactionsurveyquestionstypeid == 5 &&

                              <RatingScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={1} end={5} title={item.name} colors={this.state.colorsOneToFive} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } />

                            }
                            {item.satisfactionsurveyquestionstypeid == 6 &&


                              <StarScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} end={5} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } />

                            }
                          </>

                        ))}
                      </>

                      :

                      <>

                        {this.state.ratingVisible &&

                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                              <p style={{ color: '#09316F' }}><strong>{this.state.feedback.DisabledCallRejectionByRequester ? '01.' : '02.'}</strong> Numa escala de <strong>1 a 5</strong>, onde 1 é muito insatisfeito e 5 é muito satisfeito, <strong>como você avalia a sua experiência com a nossa Central de Atendimento?</strong></p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                              <div className="vote-gradient">
                                {/*<div
                                      className={"vote-number" + (this.state.starRating == 0 ? ' vote-number-selected' : '')}
                                      style={{ marginRight: 2, backgroundColor: '#EC475C' }}
                                      onClick={() => {
                                        this.setState({ starRating: 0 })
                                      }} >0</div>*/}
                                <div
                                  className={"vote-number" + (this.state.starRating == 1 ? ' vote-number-selected' : '')}
                                  style={{ marginRight: 2, backgroundColor: '#EC475C' }}
                                  onClick={() => {
                                    this.setState({ starRating: 1 })
                                  }} >1</div>
                                <div
                                  className={"vote-number" + (this.state.starRating == 2 ? ' vote-number-selected' : '')}
                                  style={{ marginRight: 2, background: '#FB8A31' }}
                                  onClick={() => {
                                    this.setState({ starRating: 2 })
                                  }} >2</div>
                                <div
                                  className={"vote-number" + (this.state.starRating == 3 ? ' vote-number-selected' : '')}
                                  style={{ marginRight: 2, backgroundColor: '#FFE44B' }}
                                  onClick={() => {
                                    this.setState({ starRating: 3 })
                                  }} >3</div>
                                <div
                                  className={"vote-number" + (this.state.starRating == 4 ? ' vote-number-selected' : '')}
                                  style={{ marginRight: 2, background: 'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)' }}
                                  onClick={() => {
                                    this.setState({ starRating: 4 })
                                  }} >4</div>
                                <div
                                  className={"vote-number" + (this.state.starRating == 5 ? ' vote-number-selected' : '')}
                                  style={{ marginRight: 2, background: '#0eae9c' }}
                                  onClick={() => {
                                    this.setState({ starRating: 5 })
                                  }} >5</div>
                                {/* <div
                                      className={"vote-number" + (this.state.starRating == 6 ? ' vote-number-selected' : '')}
                                      style={{ marginRight: 2, backgroundColor: '#FFE44B' }}
                                      onClick={() => {
                                        this.setState({ starRating: 6 })
                                      }} >6</div>
                                    <div
                                      className={"vote-number" + (this.state.starRating == 7 ? ' vote-number-selected' : '')}
                                      style={{ marginRight: 2, backgroundColor: '#FFE44B' }}
                                      onClick={() => {
                                        this.setState({ starRating: 7 })
                                      }} >7</div>
                                    <div
                                      className={"vote-number" + (this.state.starRating == 8 ? ' vote-number-selected' : '')}
                                      style={{ marginRight: 2, background: 'linear-gradient(90deg, #FFE44B 0%, #10A9FD 100%)' }}
                                      onClick={() => {
                                        this.setState({ starRating: 8 })
                                      }} >8</div>
                                    <div
                                      className={"vote-number" + (this.state.starRating == 9 ? ' vote-number-selected' : '')}
                                      style={{ marginRight: 2, backgroundColor: '#10A9FD' }}
                                      onClick={() => {
                                        this.setState({ starRating: 9 })
                                      }} >9</div>
                                    <div
                                      className={"vote-number" + (this.state.starRating == 10 ? ' vote-number-selected' : '')}
                                      style={{ backgroundColor: '#10A9FD' }}
                                      onClick={() => {
                                        this.setState({ starRating: 10 })
                                      }} >10</div>*/}
                              </div>
                            </Grid>
                          </>

                        }


                      </>

                    }

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '3%' }} >
                      <p style={{ color: '#09316F' }}><strong>Comentários:</strong> <span style={{ fontSize: '0.8em' }} >(Opcional)</span></p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <TextField
                        variant="outlined"
                        id="outlined-multiline-static"
                        label={intl.formatMessage({ id: "comment" })}
                        name="description"
                        disabled={this.state.disabledButtons}
                        multiline
                        rows="8"
                        fullWidth
                        inputProps={{ maxLength: 800 }}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '8%' }} >
                      <Grid container spacing={1} >
                        <Grid item xs={12} sm={12} md={6} lg={8} xl={8} />

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >


                          <Button
                            onClick={this.handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            id="submit"
                            disabled={this.state.disabledButtons}
                            style={{ width: "200px", backgroundColor: '#09316F', fontWeight: 'bold', fontSize: '1em', borderRadius: '20px' }} >
                            ENVIAR AVALIAÇÃO
                          </Button>



                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>

            }

            {/*CASO PARA LINK EXPIRADO */}
            {this.state.feedbackError && this.state.ExpiredLink &&
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      O prazo para realizar essa pesquisa de satisfação foi expirado
                    </b>
                  </p>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      Clique no botão abaixo para reenviar um novo link.
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '160px' }}>
                  {!this.state.disabledResendButton && (
                    <>
                      {this.ButtonExpired}
                    </>
                  )}
                </Grid>
              </Grid>
            }
            {/*CASO PARA PESQUISA RESPONDIDA */}
            {this.state.feedbackError && this.state.feedbackProvided &&
              <Grid item xs={12} >
                <Grid item xs={12}>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      Essa pesquisa já foi respondida.
                    </b>
                  </p>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      Sua opinião é muito importante e vai nos ajudar a melhorar o atendimento.
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12}>
                  {!this.state.disabledResendButton && (
                    <>
                      {this.ButtonExpired}
                    </>
                  )}
                </Grid>
              </Grid>
            }

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ alignSelf: 'flex-end' }}>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4%' }} >
                <MEInternetIcon style={{ width: 30 }} />&nbsp;<strong style={{ color: '#2D4B7A' }} > <a href="https://www.gov.br/gestao/pt-br/assuntos/central-de-atendimento" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>www.gov.br/centraldeatendimento</a></strong>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: '#09316F', display: 'flex', justifyContent: 'center', padding: '5% 0', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '2%' }} >
                <MELogo style={{ width: '40%' }} />
              </Grid>
            </Grid>

          </Grid>
          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
          <DialogClientFeedbackSuccess
            open={this.state.dialogSuccessFeedback}
            handleCloseDialogSuccessFeedback={this.handleCloseDialogSuccessFeedback}
          />
        </Container >
      )
    } else {
      return (
        <Container component="main" maxWidth="md">

          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: 0 }}>
            <TODOFeedbackHeader style={{ width: '100%', maxWidth: '100%' }} />
            <div style={{ backgroundColor: '#f4781f', color: '#FFF', padding: '2% 10% 1% 5%', display: 'inline-block', borderTopRightRadius: 150, borderBottomRightRadius: 150, position: 'relative', left: 0, bottom: '15%' }} >
              <h4 style={{ display: 'inline-block', margin: 0 }}>
                {this.state.feedbackError && this.state.ExpiredLink && this.state.showContent &&
                  <strong>Prazo de pesquisa expirado!</strong>
                }
                {this.state.feedbackError && this.state.feedbackProvided && this.state.showContent &&
                  <strong>Agradecemos a colaboração!</strong>
                }
                {!this.state.feedbackError && this.state.showContent &&
                  <strong>Ajude a melhorar nosso atendimento!</strong>
                }


              </h4>
              <br />
              {!this.state.feedbackError && this.state.showContent &&
                <h5 style={{ display: 'inline-block', margin: 0 }}>Sua opinião é muito importante!</h5>
              }

            </div>
          </Grid>

          {!this.state.feedbackError && this.state.showContent &&
            <>

              {this.state.feedback.feedbackBo && this.state.feedback.feedbackBo != "" && this.state.feedback.feedbackBo > 0 ? (
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div>
                    <h4 style={styles.regularFont} ><CallEndIcon /> Chamado #{this.state.feedback.calledId} • {this.state.feedback.title} Já foi avaliado!</h4>
                  </div>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div>
                    <h4 style={styles.grayFont}> <AccountCircleIcon style={styles.alignMiddle} /> <span style={styles.regularFont} > {intl.formatMessage({ id: "modalRating.called.user" })} :</span> <span>{this.state.feedback.technicalName}</span> </h4>
                  </div>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div>
                    <h4 style={styles.grayFont}> <DescriptionIcon style={styles.alignMiddle} /> <span style={styles.regularFont}>{intl.formatMessage({ id: "description" })}</span> <span>{ReactHtmlParser(this.state.feedback.calledDescription)}</span> </h4>
                  </div>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div>
                    <Accordion style={{ marginTop: "10px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`last-accompaniment-content`}
                        id={`alast-accompaniment-header`} >
                        <PlaylistAddCheckIcon style={styles.alignMiddle} /><span style={styles.regularFont}>Ultimo Acompanhamento :</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p><div dangerouslySetInnerHTML={{ __html: this.state.feedback.lastaccompainiment }} /></p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div>
                    <Rating
                      style={{ fontSize: "45px" }}
                      readOnly
                      value={this.state.feedback.feedbackBo}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                    <div>
                      <h4 style={styles.regularFont} >Avalie seu chamado</h4>
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <h4 style={styles.grayFont}> <CallEndIcon style={styles.alignMiddle} /> Chamado {this.state.feedback.calledId} • {this.state.feedback.title}</h4>
                    </div>
                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                  </div>
                  <div>
                    <h4 style={styles.grayFont}> <AccountCircleIcon style={styles.alignMiddle} /> <span style={styles.regularFont} > {intl.formatMessage({ id: "modalRating.called.user" })}: </span> <span style={{ whiteSpace: 'pre' }}> {this.state.feedback.technicalName}</span> </h4>
                  </div>

                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                  <div>
                    <h4 style={styles.grayFont}> <DescriptionIcon style={styles.alignMiddle} /> <span style={styles.regularFont}>{intl.formatMessage({ id: "description" })}: </span> <span> {ReactHtmlParser(this.state.feedback.calledDescription)}</span> </h4>
                  </div>

                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                  <div>
                    <Accordion style={{ marginTop: "10px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`last-accompaniment-content`}
                        id={`alast-accompaniment-header`} >
                        <PlaylistAddCheckIcon style={styles.alignMiddle} /><span style={styles.regularFont}>Ultimo Acompanhamento :</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p><div dangerouslySetInnerHTML={{ __html: this.state.feedback.lastaccompainiment }} /></p>
                      </AccordionDetails>
                    </Accordion>

                  </div>
                  {!this.state.feedbackError && !this.state.ExpiredLink && this.state.showContent &&
                    <>
                      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                      {this.state.showPresentedSolutionQuestion && !this.state.feedback.DisabledCallRejectionByRequester &&  this.state.showCalledRejection && (

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
                          <p style={{ color: '#09316F' }}><strong>01.</strong> Você está de acordo com a solução apresentada? </p>
                        </Grid>
                      )
                      }


                      {!this.state.feedback.DisabledCallRejectionByRequester && this.state.showCalledRejection &&

                        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} style={{ marginTop: "15px" }} >
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                            <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === true ? '#CCC' : '#EEE', borderRight: '1px solid #CCC', cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({ ratingVisible: true })
                              }}>
                              <p style={{ color: '#595959', paddingRight: '10%' }}><strong>SIM</strong></p>
                              <MELikeIcon style={{ width: 30 }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === false ? '#CCC' : '#EEE', borderLeft: '1px solid #CCC', cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({ ratingVisible: false })
                              }}>
                              <p style={{ color: '#595959', paddingRight: '10%' }}><strong>NÃO</strong></p>
                              <MEUnlikeIcon style={{ width: 30 }} />
                            </div>
                          </div>
                        </Grid>



                      }

                      {this.state.satisfactionSurveyQuestions.length && this.state.satisfactionSurveyQuestions.length > 0 ?

                        <>
                          {this.state.satisfactionSurveyQuestions.map((item, index) => (
                            <>
                              {item.satisfactionsurveyquestionstypeid == 1 &&

                                <ThumbsUpOrDown fqa={this.state.firstQuestionValue} newlayout={false} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} title={item.name} />

                              }
                              {item.satisfactionsurveyquestionstypeid == 2 &&

                                <RatingScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={0} end={10} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } colors={this.state.colorsZeroToTen} />

                              }
                              {item.satisfactionsurveyquestionstypeid == 3 &&

                                <RatingScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={1} end={10} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } colors={this.state.colorsOneToTen} />

                              }
                              {item.satisfactionsurveyquestionstypeid == 4 &&

                                <RatingScaleOldLayout starRating={this.state.starRating} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} end={10} title={item.name} index={index} />

                              }
                              {item.satisfactionsurveyquestionstypeid == 5 &&

                                <RatingScale starRating={this.state.starRating} indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} initial={1} end={5} title={item.name} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } colors={this.state.colorsOneToFive} />

                              }
                              {item.satisfactionsurveyquestionstypeid == 6 &&

                                <RatingScaleOldLayout starRating={this.state.starRating} index={index} disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} end={5} title={item.name} index={index} />

                              }
                            </>

                          ))}
                        </>

                        :

                        <>
                          {this.state.ratingVisible && (
                            <div style={{ marginTop: "15px" }}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <p style={{ color: '#09316F' }}><strong>{this.state.feedback.DisabledCallRejectionByRequester ? '01.' : '02.'}</strong> O que você achou do Atendimento?</p>
                              </Grid>
                              <div style={{ marginTop: "6px" }}>
                                <h4 style={styles.grayFont}> Escolha de 1 a 5 estrelas para avaliar</h4>
                              </div>
                            </div>
                          )}
                          <Grid item xs={12} sm={12} container alignItems="center" justifyContent="center" direction="row" style={{ display: this.state.ratingVisible ? '' : 'none' }}>
                            <Rating
                              style={{ fontSize: "45px" }}
                              name="rating"
                              required
                              disabled={this.state.disabledButtons}
                              value={this.state.starRating}
                              onChange={(event, newValue) => {
                                this.setState({
                                  starRating: newValue
                                });
                              }}
                            />
                          </Grid>


                        </>

                      }
                    </>
                  }




                  {!this.state.ratingVisible && (
                    <div style={{ marginTop: "15px" }}>
                      <div>
                        <h4 style={styles.regularFont} > Explique um pouco sobre o que aconteceu </h4>
                      </div>
                    </div>
                  )}
                  <form className={classes.form} onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>


                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          id="outlined-multiline-static"
                          label={intl.formatMessage({ id: "comment" })}
                          name="description"
                          disabled={this.state.disabledButtons}
                          multiline
                          rows="8"
                          fullWidth
                          variant="outlined"
                          inputProps={{ maxLength: 800 }}
                          onChange={this.handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <div style={{ textAlign: "right", marginTop: '20px' }}>




                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        id="submit"
                        disabled={this.state.disabledButtons}
                        style={{ width: "200px" }}

                      >
                        ENVIAR AVALIAÇÃO
                      </Button>


                    </div>



                  </form>
                </div>
              )}

            </>

          }

          {this.state.feedbackError && this.state.ExpiredLink &&
            <Grid item xs={12} style={{ marginTop: '50px' }}>
              <Grid item xs={12}>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#335386" }}>
                    O prazo para realizar essa pesquisa de satisfação foi expirado
                  </b>
                </p>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#335386" }}>
                    Clique no botão abaixo para reenviar um novo link.
                  </b>
                </p>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '160px' }}>
                {!this.state.disabledResendButton && (
                  <>
                    {this.ButtonExpired}
                  </>
                )}
              </Grid>
            </Grid>
          }
          {/*CASO PARA PESQUISA RESPONDIDA */}
          {this.state.feedbackError && this.state.feedbackProvided &&
            <Grid item xs={12} style={{ marginTop: '50px' }}>
              <Grid item xs={12}>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#f4781f" }}>
                    Essa pesquisa já foi respondida.
                  </b>
                </p>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#f4781f" }}>
                    Sua opinião é muito importante e vai nos ajudar a melhorar o atendimento.
                  </b>
                </p>
              </Grid>
              <Grid item xs={12}>
                {!this.state.disabledResendButton && (
                  <>
                    {this.ButtonExpired}
                  </>
                )}
              </Grid>
            </Grid>
          }


          <DialogClientFeedbackSuccess
            open={this.state.dialogSuccessFeedback}
            handleCloseDialogSuccessFeedback={this.handleCloseDialogSuccessFeedback}
          />

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />




        </Container>
      );
    }


  }
}


export default injectIntl(withStyles(Styles)(Feedback));
