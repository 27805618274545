import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Avatar, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import * as CalledService from "../../services/called.service";
import CategoryIcon from '@mui/icons-material/Category';
import Api from '../../services/api';
import { browserHistory } from '../../helpers/history';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as OrgDepartamentService from '../../services/organizationdepartament.service';
import Dropzone from 'react-dropzone';
import moment from "moment";
import * as CalledOrganizationChecklistService from '../../services/calledorganizationchecklist.service'
import { QueuePlayNext, DeleteForever, ExpandMore } from "@mui/icons-material";
import If from '../../components/conditionals/if'

import {
    TextField,
} from "@mui/material";
import { getDepartamentsByOrganizationCategoryIdNew, getCategoriesByOrganizationDepartamentId } from '../../services/organizationcategory.service';
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ReactQuill, { Quill } from 'react-quill'; // ES6
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { withRouter } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SimpleCheckBox from '../../components/checkbox/check';
import * as componentActions from '../../redux/actions/organizationcategoriesportal';
import { PapiroConsole } from '../../utils/papiroConsole';
import ClearIcon from '@mui/icons-material/Clear';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete-categories';
import Search from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CardMedia from '@mui/material/CardMedia';
import organizationcategoryportal from '../../redux/reducers/organizationcategoryportal';
import Childrencardcategories from '../portal/components/childrencardcategories/childrencardcategories';
import Cardcategories from '../portal/components/cardcategories/cardcategories';
import ModalRating from "../../components/ModalRating";



class CardInformations extends Component {

    constructor(props) {
        super(props);

        const intl = this.props.intl;


        let fromProps = ""
        let organizationCategory = null


        this.reactQuillModules = {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean'],
            ],
        }

        this.reactQuillFormats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'color', 'font'
        ]

        const defaultvalue = { id: 0, name: "", parentname: "" };
        const { userSession } = this.props;

        if (userSession.user && userSession.user.organizationlistid) {
            this.userOrganizations = userSession.user.organizationlistid;
        }
        this.typingTimeout = null;
        this.initialCalledState = {
            requestingUser: userSession.user.id,
            createdUser: userSession.user.id,
            organization: null,
            type: 0,
            CategoryId: defaultvalue,
            categoryText: "",
            dueDate: moment()
                .add("days", 1)
                .format("YYYY/MM/DD HH:mm:ss"),
            title: "",
            status: 0,
            description: "",
            AddressId: 0,
            originRequest: 9,
            observantUser: 0,
            assignedUser: 0,
            patrimonyId: "",
            departamentId: 0,

        }
        this.state = {
            informativeText: '',
            informativeTextAccordionExpanded: true,
            userOrganizations: this.userOrganizations,
            organizationAddressesList: [],
            urlImage: null,
            newCategoryDepartament: false,
            campoTexto: null,
            canselectorganizationcategoriesbylevels: false,
            organizationFieldList: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultCategories: [],
            resultCategoriesOfPortal: [],
            files: [],
            organizationCategory: {
                id: 0,
                name: '',
                parentName: '',
                description: '',
                information: '',
                canCreateCalled: false,
                organizationId: 0,
                sla: 0,
            },
            called: this.initialCalledState,
            tabvalue: 0,
            calledInfoDialogOpen: false,
            calledInfoDialogId: 0,
            calledInfoDialogTitle: '',
            calledInfoDialogDueDate: '',
            modalRatingOpen: false,
            unratedCalledsList: [],
        };

        this.organizationId = null;
        this.userDepartamentId = 0;
        this.roleClientCanSelectCategory = false;
        this.canClientSelectPatrimonyInCalled = false;
        this.canClientSelectTypeInCalled = false;
        this.restrictviewcalleddepartament = false;
        this.canselectpatrimonyincalled = false;
        this.canselectdepartamentincalled = false
        this.isClient = true;
        this.globalAdm = false;
        this.modalRating = null;
        this.displayassignedinfomode = 0;
        this.requiredratingcalleds = false;
        this.userid = userSession.user.id;
        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;

            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        }
        );
        if (userSession.user.organizationdepartamentid && userSession.user.organizationdepartamentid > 0) {
            this.userDepartamentId = userSession.user.organizationdepartamentid;
        }

        this.moveToAnotherPage = this.moveToAnotherPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getImage = this.getImage.bind(this);
        this.getRootCategoriesByOrganization = this.getRootCategoriesByOrganization.bind(this);
        this.getCategoryChildrens = this.getCategoryChildrens.bind(this);
        this.getAllCategoryOfPortal = this.getAllCategoryOfPortal.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.handleChangeEditorDescription = this.handleChangeEditorDescription.bind(this);
        this.getOrganizationCategoryById = this.getOrganizationCategoryById.bind(this);
        this.getOrganizationCategoryByIdAndMoveToAnotherPage = this.getOrganizationCategoryByIdAndMoveToAnotherPage.bind(this);
        this.validateImagesAtachments = this.validateImagesAtachments.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.changeValuesCheckbox = this.changeValuesCheckbox.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getOrganizationSettings = this.getOrganizationSettings.bind(this)
        this.getOrganizationAddresses = this.getOrganizationAddresses.bind(this)
        this.getDepartamentsByOrganizationCategoryId = this.getDepartamentsByOrganizationCategoryId.bind(this)
        this.getCategoryFields = this.getCategoryFields.bind(this);
        this.getCategoriesByOrganization = this.getCategoriesByOrganization.bind(this)
        this.getOrganizationDepartaments = this.getOrganizationDepartaments.bind(this);
        this.backHistory = this.backHistory.bind(this)
        this.closeCalledInfoDialog = this.closeCalledInfoDialog.bind(this)
        this.changeDueDateByOrganizationCagetoryMessage = this.changeDueDateByOrganizationCagetoryMessage.bind(this)
        this.handleChangeAccordion = this.handleChangeAccordion.bind(this)
        this.getUnratedCalleds = this.getUnratedCalleds.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.setMessage = this.setMessage.bind(this)
    }

    handleChangeAccordion = (accordion) => (event, isExpanded) => {
        if (accordion === "informative-text")
            this.setState({ informativeTextAccordionExpanded: isExpanded })
    }

    closeCalledInfoDialog = () => this.setState({ calledInfoDialogOpen: false, calledInfoDialogId: 0, calledInfoDialogTitle: '', calledInfoDialogDueDate: '' })

    
    
    
    
    /*async componentWillReceiveProps(nextProps) {
        PapiroConsole.log("entrei aqui no componentWillReceiveProps ")
        PapiroConsole.log(nextProps)
        if (nextProps.location && nextProps.location.state && nextProps.location.state.action && nextProps.location.state.action === "cardReload") {
            await this.componentDidMount()
        }


    }*/

    componentDidUpdate(prevProps) {
        PapiroConsole.log("entrei aqui no didupdate")
        PapiroConsole.log(this.props.match.params.id)
        PapiroConsole.log(prevProps.match.params.id)
        if (this.props.match.params.id !== prevProps.match.params.id) {
          this.initializateData(this.props.match.params.id);
        }
      }

    async componentDidMount() {

        this.initializateData();

    }



    async initializateData() {

        
        
        const { organizationcategoryportal } = this.props;
        PapiroConsole.log("organizationcategoryportal")
        PapiroConsole.log(organizationcategoryportal)
        const { id } = this.props.match.params

        

        PapiroConsole.log(id)
        PapiroConsole.log("id here")

        var elementId = organizationcategoryportal.organizationcategoryportal.id != null ? organizationcategoryportal.organizationcategoryportal.id : 0
        //var parentElementId =this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.parentId != null ? this.props.history.location.state.parentId : 0
        PapiroConsole.log("checar if")
        PapiroConsole.log(id)
        PapiroConsole.log(elementId)




        if (id == elementId) {

            var checkCategory = true
            // função para checar se o cliente tem acesso a aquela categoria
            if (this.globalAdm == false) {
                checkCategory = await this.checkCategoryById(id)

            }

            if (checkCategory == true) {
                PapiroConsole.log("entrei no if")
                PapiroConsole.log(organizationcategoryportal)

                console.log("=== componentDidMount [CardInformations] ===")
                console.log(dueDate)
                console.log(organizationcategoryportal.organizationcategoryportal)

                let { dueDate } = this.state
                if (organizationcategoryportal.organizationcategoryportal.useslatime != null && organizationcategoryportal.organizationcategoryportal.useslatime) {
                    if (organizationcategoryportal.organizationcategoryportal.organizationsla && organizationcategoryportal.organizationcategoryportal.organizationsla.solutiontime && organizationcategoryportal.organizationcategoryportal.organizationsla.solutiontime > 0) {
                        dueDate = moment().add(organizationcategoryportal.organizationcategoryportal.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
                    } else if (organizationcategoryportal.organizationcategoryportal.solutiontime && organizationcategoryportal.organizationcategoryportal.solutiontime > 0) {
                        dueDate = moment().add(organizationcategoryportal.organizationcategoryportal.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
                    }
                } else if (organizationcategoryportal.organizationcategoryportal.useslatime != null && !organizationcategoryportal.organizationcategoryportal.useslatime) {
                    if (organizationcategoryportal.organizationcategoryportal.solutiontime && organizationcategoryportal.organizationcategoryportal.solutiontime > 0) {
                        dueDate = moment().add(organizationcategoryportal.organizationcategoryportal.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
                    }
                }

                console.log("=== componentDidMount -> 2 [CardInformations] ===")
                console.log(dueDate)

                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        description: '',
                        dueDate: dueDate
                    },
                    organizationCategory: {
                        id: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.id : 0,
                        name: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.name : "",
                        parentName: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.parentname : "",
                        description: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.description : "",
                        information: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.information : "",
                        canCreateCalled: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.cancreatecalled : false,
                        organizationId: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.organizationid : 0,
                        sla: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.organizationslaid : 0,
                        type: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.typeid : 0,
                        priorityId: organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.priorityid : 0
                    },
                }));

                this.organizationId = organizationcategoryportal.organizationcategoryportal ? organizationcategoryportal.organizationcategoryportal.organizationid : 0

                if (organizationcategoryportal.organizationcategoryportal != null && organizationcategoryportal.organizationcategoryportal.cancreatecalled != null && organizationcategoryportal.organizationcategoryportal.cancreatecalled) {
                    await this.getOrganizationSettings()
                    PapiroConsole.log("entrei aqui x vezes")
                    await this.getUnratedCalleds()
                }
                else
                    this.setState({ informativeText: "", informativeTextAccordionExpanded: true })
                //pegar categorias filhas
                await this.getCategoryChildrens(elementId)
                PapiroConsole.log("pegar categorias filhas")
                await this.getAllCategoryOfPortal()

                //pegar imagens das categorias
                if (organizationcategoryportal.organizationcategoryportal && organizationcategoryportal.organizationcategoryportal.attachmentid) {

                    await this.getImage(organizationcategoryportal.organizationcategoryportal.attachmentid);
                }
                else {
                    this.setState({
                        urlImage: null
                    })
                }
                //checando se pode criar chamado e não tem categorias filhas
                if (this.state.organizationCategory.canCreateCalled == true && this.state.resultCategories.length == 0) {
                    await this.getOrganizationAddresses()
                    //await this.getDepartamentsByOrganizationCategoryId(this.state.organizationCategory.id)
                    await this.getCategoryFields(this.state.organizationCategory.id)


                }
            }





        }
        else {
            PapiroConsole.log("entrei no else")
            PapiroConsole.log("id do else")
            PapiroConsole.log(id)
            await this.getOrganizationCategoryById(id)
        }





    }

    checkCategoryById = async (id) => {

        this.setState({ loading: true })
        var getResult = false
        await Api.get(`/organizationcategories/checkifcanaccesscategory?org=${this.state.userOrganizations}&categoryid=${id}&`)
            .then(async result => {

                if (result.data.success) {
                    PapiroConsole.log(result)
                    PapiroConsole.log(result.data.data.length)

                    if (result.data.data && result.data.data.length != 0) {
                        PapiroConsole.log("entrei aqui no if certo")
                        getResult = true

                    }

                    this.setState({ loading: false })

                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }




            });


        if (getResult == false) {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O usuário não tem acesso a esta categoria ou ela não está configurada para aparecer no portal"
            });

        }
        return getResult
    }

    async getOrganizationFields(organizationId) {
        if (organizationId == null) {

            return null;
        }
        if (!this.isClient) {
            var result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganizationAndCategories(organizationId);
        }
        else {
            result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganizationCustomerEdit(organizationId);
        }


        if (result.success) {

            this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });


            Object.keys(this.state.called).forEach((key) => {

                if (key.includes("ofield-")) {
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            [key]: undefined

                        }
                    }))
                }

            })

            if (result.data) {


                result.data.forEach(element => {

                    if (element.organizationfieldtype == 3) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                ['ofield-' + element.id]: true

                            }
                        }))
                    }
                    else {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                ['ofield-' + element.id]: ""

                            }
                        }))
                    }



                });

                this.returnFields();

            }
            //this.returnFields();
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };
    async getCategoryFields(organizationCategoryId) {


        if (this.isClient) {
            var result = await CalledOrganizationChecklistService.GetByOrganizationCategoryIdAndActiveCustomerEdit(organizationCategoryId);
        }
        else {

            var result = await CalledOrganizationChecklistService.GetByOrganizationCategoryIdAndActive(organizationCategoryId);
        }


        if (result.success) {

            if (result.data.length > 0) {

                this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                Object.keys(this.state.called).forEach((key) => {

                    if (key.includes("ofield-")) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                [key]: undefined

                            }
                        }))
                    }
                })

                if (result.data) {

                    result.data.forEach(element => {

                        if (element.organizationfieldtype == 3) {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + element.id]: true

                                }
                            }))
                        }
                        else {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    ['ofield-' + element.id]: ""

                                }
                            }))
                        }



                    });

                    this.returnFields();

                }

            } else {

                this.getOrganizationFields(this.organizationId)
            }




            //this.returnFields();*/



        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    getDepartamentsByOrganizationCategoryId = async (id) => {

        var result = await getDepartamentsByOrganizationCategoryIdNew(id);
        if (result.success) {
            if (result.data && result.data.length > 0) {
                if (this.restrictviewcalleddepartament === true && this.state.userDepartamentId && this.state.userDepartamentId.id && this.state.userDepartamentId.id > 0) {

                    var updatedList = result.data.filter(item => item.id == this.state.userDepartamentId.id)
                    if (updatedList)
                        updatedList = updatedList[0]

                    if (result.data.length == 1) {
                        this.setState(prevState => ({
                            called: {
                                ...prevState.called,
                                departamentId: result.data[0]
                            }
                        }))
                    }

                    this.setState({ loading: false, DepartamentList: [updatedList] });
                } else {

                    if (this.state.called.departamentId && this.state.called.departamentId.id) {

                        var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)
                        if (updatedList)
                            updatedList = updatedList[0]

                        if (result.data.length == 1) {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState,
                                    departamentId: result.data[0]
                                }
                            }))
                        }

                        this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
                    } else {

                        this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
                    }
                }
                this.setState({ newCategoryDepartament: false })
            } else {

                this.getOrganizationDepartaments(this.organizationId)
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    getOrganizationDepartaments = async (id) => {
        const { userSession } = this.props;

        //this.setState({ loading: true });

        var result = await OrgDepartamentService.getOrganizationsDepartaments(id);
        if (result.success) {
            if (this.state.called.departamentId && this.state.called.departamentId.id) {
                var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)

                this.setState({
                    DepartamentList: updatedList.length > 0 ? (updatedListAtt && updatedListAtt.length > 1 ? updatedListAtt.sort((a, b) => a.name.localeCompare(b.name)) : updatedListAtt) : (result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data),
                    newCategoryDepartament: true
                });
            } else {
                let userDepartaments = this.state.userDepartamentId

                if (userDepartaments != null) {
                    var updatedListAtt = result.data.filter(item => item.id == userDepartaments.id)

                    this.setState({
                        DepartamentList: updatedListAtt.length > 0 ? (updatedListAtt && updatedListAtt.length > 1 ? updatedListAtt.sort((a, b) => a.name.localeCompare(b.name)) : updatedListAtt) : (result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data),
                        newCategoryDepartament: true
                    }, () => {
                        if (updatedListAtt.length > 0) {
                            this.setState(prevState => ({
                                called: {
                                    ...prevState.called,
                                    departamentId: updatedListAtt[0]
                                }
                            }))
                        }
                    });
                } else {
                    this.setState({
                        DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data,
                        newCategoryDepartament: true
                    });
                }
            }
        } else {
            const intl = this.props.intl;
            this.setState({
                newCategoryDepartament: false,
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }


    async getOrganizationSettings() {
        let infos;
        let categoryLevel = false
        await Api.get(`/organizationsettings/organization/${this.state.organizationCategory.organizationId}`)
            .then((result) => {
                if (result.data.success) {
                    this.requiredratingcalleds = result.data.data.requiredratingcalleds
                    infos = result.data.data;
                    this.canselectpatrimonyincalled = result.data.data.canselectpatrimonyincalled;
                    this.canselectdepartamentincalled = result.data.data.canselectdepartamentincalled
                    this.roleClientCanSelectCategory = result.data.data.roleclientcanselectcategory;
                    this.canClientSelectPatrimonyInCalled = result.data.data.canclientselectpatrimonyincalled;
                    this.canClientSelectTypeInCalled = result.data.data.canclientselecttypeincalled;
                    this.restrictviewcalleddepartament = result.data.data.restrictviewcalleddepartament
                    this.displayassignedinfomode = result.data.data.displayassignedinfomode
                    categoryLevel = result.data.data.canselectorganizationcategoriesbylevels
                    this.setState(prevState => ({
                        canselectorganizationcategoriesbylevels: result.data.data.canselectorganizationcategoriesbylevels,
                        informativeText: result.data.data.informativetext != null ? result.data.data.informativetext : "",
                        informativeTextAccordionExpanded: true,
                        organizationCategory: {
                            ...prevState.organizationCategory,
                            type: result.data.data.type.id
                        }
                    }))
                }
            })
            .catch((err) => {
                const intl = this.props.intl;
                this.setState({
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response && err.response.data && err.response.data.errors
                            ? err.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });

            });
        /* if (this.organizationId && this.organizationId > 0) {
             if (categoryLevel === true)
                 await this.getRootCategoriesByOrganization(this.organizationId);
             else
                 await this.getCategoriesByOrganization(this.organizationId)
         }*/
        return infos;
    }

    getCategoriesByOrganization = async (organizationId) => {

        if (organizationId == null) {

            return null;
        }

        const { userSession } = this.props;

        if (this.restrictviewcalleddepartament === true && this.state.userDepartamentId && this.state.userDepartamentId.id) {

            var result = await getCategoriesByOrganizationDepartamentId(this.state.userDepartamentId.id)
            if (result.success) {
                this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

            } else {
                const intl = this.props.intl;
                this.setState({

                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.response && result.response.data && result.response.data.errors
                            ? result.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });

            }
        } else {
            var result = await CalledService.getCategoriesByOrganization(
                organizationId
            );

            if (result.success) {
                this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

            } else {
                const intl = this.props.intl;
                this.setState({

                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.response && result.response.data && result.response.data.errors
                            ? result.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });

            }
        }
    }

    getRootCategoriesByOrganization = async (organizationId) => {
        const intl = this.props.intl;
        var result = await CalledService.getRootCategoriesByOrganization(organizationId);
        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
                    if (this.AdmOrganization) {
                        const intl = this.props.intl;
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" }),
                            notificationLinkMove: "organizationcategories"
                        });
                    } else {
                        const intl = this.props.intl;
                        this.setState({
                            openNotification: true, notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "called.accompaniment.notification.missing.organization.categories" })
                        });
                    }
                }
            }
            this.setState({
                levelCategories: {
                    rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
                },
                categoryLists: [
                    result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
                ]
            });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    getOrganizationAddresses = async () => {

        const result = await CalledService.getOrganizationAddresses(
            this.state.organizationCategory.organizationId
        );

        if (result.success) {
            this.setState({ organizationAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

            if (result.data && result.data.length == 1) {
                this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        AddressId: result.data[0]
                    },
                }))
            }

            else if (result.data && result.data.length > 1) {
                var getLocation = null

                result.data.forEach(element => {
                    if (element.defaultlocation) {
                        getLocation = element
                    }
                });

                if (getLocation != null) {
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            AddressId: getLocation
                        },
                    }))
                }
                else {
                    this.setState(prevState => ({
                        called: {
                            ...prevState.called,
                            AddressId: result.data[0]
                        },
                    }))
                }
            }
            else {
                this.setState({

                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessária ter uma localização associada a organização"
                });
            }



        } else {
            if (result.response) {
                const intl = this.props.intl;
                this.setState({

                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.response &&
                            result.response.data &&
                            result.response.data.errors
                            ? result.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
            } else {
                const intl = this.props.intl;
                this.setState({

                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        result.response &&
                            result.response.data &&
                            result.response.data.errors
                            ? result.response.data.errors[0]
                            : "Erro ao processar a solicitação: " + result.response,
                });
            }

        }
    }

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true });


        let data = new FormData();

        data.append("organization", this.state.organizationCategory.organizationId);
        const clientInfos = await this.getOrganizationSettings();

        PapiroConsole.log(clientInfos)
        PapiroConsole.log("clientInfos aqui")

        if (clientInfos) {
            //console.log(clientInfos);
            this.setState((prevState) => ({
                called: {
                    ...prevState.called,
                    originRequest: 9,
                    sla: this.state.organizationCategory.sla,
                    status: clientInfos.status ? clientInfos.status.id : 1,
                    type: this.state.organizationCategory.type != 0 ? this.state.organizationCategory.type : clientInfos.type && clientInfos.type.id ? clientInfos.type.id : 0,
                    OrganizationCategory: this.state.organizationCategory.id
                        ? this.state.organizationCategory.id
                        : 0,
                    priorityId: this.state.organizationCategory.priorityId != 0 ? this.state.organizationCategory.priorityId : 1
                },
                loading: true
            }));

            if (clientInfos.techinicalteam && clientInfos.techinicalteam.id && clientInfos.techinicalteam.id > 0)
                data.append("techinicalTeamId", clientInfos.techinicalteam.id)

            this.state.files.forEach((file) => data.append("filesUpload", file));
            let calledOrganizationFieldList = []

            for (let key in this.state.called) {
                if (key === "dueDate") {
                    data.append(
                        key,
                        moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss")
                    );
                } else if (
                    key
                        .toString()
                        .toLowerCase()
                        .indexOf("categoryid") > -1
                    && this.state.called[key]
                ) {
                    data.append("Category", this.state.called[key].id);
                } else if (key.toString().toLowerCase().indexOf('addressid') > -1) {
                    if (this.state.called[key].id) {
                        if (!isNaN(this.state.called[key].id)) {
                            data.append("AddressId", this.state.called[key].id);
                        }
                        else {
                            data.append("AddressId", 0);
                        }
                    } else {
                        data.append("AddressId", 0);
                    }
                } else if (key.toString().toLowerCase().indexOf('departamentid') > -1) {
                    let deptVal = this.state.called[key].id
                    if (deptVal == null || typeof deptVal === 'undefined')
                        deptVal = 0
                    data.append("OrganizationDepartamentId", deptVal);
                } else if (key.toString().toLowerCase().indexOf('assigneduser') > -1) {
                    if (clientInfos.defaulttechinicalid) {
                      data.append("assignedUser", clientInfos.defaulttechinicalid)
                    }
                  } else {
                    if (key.toString().toLowerCase().indexOf("ofield-") > -1) {
                        calledOrganizationFieldList.push(this.state.called.key)
                    } else
                        data.append(key, this.state.called[key]);
                }
            }

            let list = this.state.called["patrimonyId"];

            if (list != null && list != "") {
                let result = "";

                for (let value in this.state.called["patrimonyId"]) {
                    result = this.state.called["patrimonyId"][value].id + "," + result;
                }

                data.append("calledOrganizationPatrimonyId", result);
            }
            if (this.isClient == true) {
                data.append("isClientCreator", true);
            }
            else {
                data.append("isClientCreator", false);
            }

            data.append("newCategoryDepartament", this.state.newCategoryDepartament)
            //data.append("priorityid", 1)

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            Object.keys(this.state.called).forEach((key) => {
                if (key.includes("ofield-") && this.state.called[key] != undefined) {


                    if (typeof this.state.called[key] == "boolean") {
                        this.state.called[key] = this.state.called[key].toString()

                    }
                    let organizationFieldOptionsValuesId
                    let explode = key.split('-')
                    let explode2 = this.state.called[key].split('\n')
                    let val = explode2[0]

                    let organizationFieldId = explode[1];
                    if (explode2[1] == undefined) {
                        organizationFieldOptionsValuesId = 0;
                    }
                    else {
                        organizationFieldOptionsValuesId = explode2[1];
                    }



                    data.append("OrgFieldIdsList", parseInt(organizationFieldId))
                    data.append("OrgFieldValuesList", val)

                    data.append("OrgFieldOptionsValuesList", organizationFieldOptionsValuesId)
                }
            })

            if(window && window.location &&  window.location.href){
                const currentURL = window.location.href;
                data.append('absoluteOrigin', currentURL)
              }



            Api.post("/calleds", data, config)
                .then((result) => {
                    if (result.data.success) {
                        if (result.data.data != null) {
                            this.setState({
                                calledInfoDialogId: result.data.data.id,
                                calledInfoDialogTitle: result.data.data.title,
                                calledInfoDialogDueDate: result.data.data.duedate ? moment(result.data.data.duedate).format("DD/MM/YYYY") : '',
                                calledInfoDialogOpen: true
                            })
                        }
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "add.success" }),
                            called: this.initialCalledState,
                            files: [],
                            disabledSaveButton: false,
                            campoTexto: null,
                            organizationFieldList: []
                        });
                        this.componentDidMount()
                    }
                })
                .catch((err) => {
                    const intl = this.props.intl;
                    this.setState({
                        disabledSaveButton: false,
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data && err.response.data.errors
                                ? err.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });
                });
        } else {
            const intl = this.props.intl;
            this.setState({
                disabledSaveButton: false,
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "called.registration.client.organization.no.config.contact.admin" }),
            });
        }

    };

    changeDueDateByOrganizationCagetoryMessage = (type, date) => {
        const intl = this.props.intl;

        switch (type) {
            case 1:
                this.setState({
                    openNotification: true,
                    notificationVariant: "info",
                    notificationMessage: intl.formatMessage({ id: "called.registration.notification.date.changed.sla.category" })
                });
                break;
            case 2:
                this.setState({
                    openNotification: true,
                    notificationVariant: "info",
                    notificationMessage: intl.formatMessage({ id: "called.registration.notification.date.changed.sla.category" })
                });
                break;
            default:
                break;
        }
    }

    handleChangeRadio = async (e) => {


        let value = e.target.value
        let name = e.target.name


        this.setState(prevState => ({
            called: {
                ...prevState.called,
                [name]: value
            }
        }))

        /*this.setState(prevState => ({
          radioChoice: {
            [name]: value
          }
        }))*/

    }

    returnFields() {
        var campoTexto = []
        var arrayseleção = []
        var fieldType = 0


        PapiroConsole.log("lista aqui")
        PapiroConsole.log(this.state.organizationFieldList)
        if (this.state.organizationFieldList.length > 0) {
            this.state.organizationFieldList.forEach(element => {
                fieldType = element.organizationfieldtype

                if (element.organizationfieldoptionsvalues.length > 0) {
                    element.organizationfieldoptionsvalues.forEach(element2 => {

                        if (fieldType == 2 || fieldType == 4) {
                            arrayseleção.push(element2)
                        }

                    });
                    if (fieldType == 2) {




                        campoTexto.push(
                            <Grid item xs={6} sm={6}>
                                <FormControl >
                                    <FormLabel id="demo-radio-buttons-group-label">{element.name}</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"


                                    >
                                        {arrayseleção.map(item =>
                                            <FormControlLabel name={'ofield-' + element.id} value={item.name + '\n' + item.id} stateName={'ofield-' + element.name} onChange={this.handleChangeRadio} control={<Radio required={element.isrequired} />} label={item.name} />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>


                        )
                        arrayseleção = []
                    }
                    if (fieldType == 4) {


                        campoTexto.push(
                            <Grid item xs={6} sm={6}>
                                <SimpleAutoComplete label={element.name} options={arrayseleção} stateName={'ofield-' + element.id} changeSelect={this.changeValues} required={element.isrequired} />
                            </Grid>

                        )
                        arrayseleção = []
                    }


                }
                else {

                    if (fieldType == 1) {
                        campoTexto.push(
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name={'ofield-' + element.id}
                                    variant="outlined"
                                    id={'ofield-' + element.id}
                                    label={element.name}
                                    fullWidth
                                    onChange={this.handleInputChange}
                                    inputProps={{ maxLength: 250 }}
                                    required={element.isrequired}
                                />
                            </Grid>

                        )
                    }
                    if (fieldType == 3) {

                        campoTexto.push(
                            <Grid item xs={6} sm={6}>
                                <SimpleCheckBox
                                    selected={true}
                                    label={element.name}
                                    name={'ofield-' + element.id}
                                    stateName={'ofield-' + element.id}
                                    changeSelect={this.changeValuesCheckbox}
                                />
                            </Grid>
                        )
                    }

                }
            });


        }





        this.setState({ campoTexto: campoTexto });


    }
    changeValues = async (stateName, value, text = "") => {
        this.setState({
            loading: true,
        });

        if (stateName === 'searchCategories') {
            console.log(value)
            console.log("value")
            if (value.id != 0) {
                await this.setState(prevState => ({
                    called: {
                        ...prevState.called,
                        [stateName]: { id: 0, name: '' }
                    }
                }));
                await this.moveToAnotherPage(value, this.state.organizationCategory.id)
            }

        }
        if (stateName.includes("ofield")) {

            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    [stateName]: value.name + '\n' + value.id
                }
            }));


        }
        else {
            this.setState((prevState) => ({
                called: {
                    ...prevState.called,
                    [stateName]: value,
                },
            }));
        }





        this.setState({
            loading: false,
        });
    }



    async changeValuesCheckbox(stateName, value, text = '') {




        if (stateName.includes("ofield")) {

            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    [stateName]: value
                }
            }));


        }

    };



    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState((prevState) => ({
            called: {
                ...prevState.called,
                [name]: value,
            },
        }));
    }

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    async backHistory() {



        if (this.fromProps) {
            await this.props.history.push(`/${this.props.match.params.lang}/portal`)
        }
        else {
            //await browserHistory.goBack()
            if (this.props.location && this.props.location.state && this.props.location.state.parentId) {

                await this.getOrganizationCategoryByIdAndMoveToAnotherPage(this.props.location.state.parentId)
            }
            else {
                await this.props.history.push(`/${this.props.match.params.lang}/portal`)
            }


        }

    }

    validateImagesAtachments(files) {


        const intl = this.props.intl;
        if (files.length > 1) {
            this.setState({
                evAttachFile: {},
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "checklist.modal.select.image.error" }),
            })
            return
        }
        else {
            this.setState({ files: files })
        }

    }

    async getOrganizationCategoryById(id) {


        this.setState({ loading: true })
        await Api.get(`/organizationcategories/withoutparents/${id}`)
            .then(async result => {


                if (result.data.success) {
                    await this.props.addCurrent(result.data.data)
                    this.setState({ loading: false })
                    await this.componentDidMount();
                }


            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }


            });



    }

    async getOrganizationCategoryByIdAndMoveToAnotherPage(id) {

        console.log("entrei nessa função")

        var checkCategory = true
        // função para checar se o cliente tem acesso a aquela categoria
        if (this.globalAdm == false) {
            checkCategory = await this.checkCategoryById(id)

        }

        if (checkCategory == true) {

            this.setState({ loading: true })
            await Api.get(`/organizationcategories/withoutparents/${id}`)
                .then(async result => {


                    if (result.data.success) {
                        //await this.props.addCurrent(result.data.data)
                        this.setState({ loading: false })
                        await this.moveToAnotherPage(result.data.data, id)
                        //await this.componentDidMount();

                    }


                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }


                });

        }







    }

    handleChangeEditorDescription(value) {


        this.setState(prevState => ({
            called: {
                ...prevState.called,
                description: value
            }
        }));


    }

    moveToAnotherPage = async (nextElement, id) => {


        await this.props.addCurrent(nextElement);
        this.props.history.push({
            pathname: `/${this.props.match.params.lang}/portal-category/${nextElement.id}`,
            state: {
                action: "cardReload",
                parentId: nextElement.parentorganizationcategoryid
            }

        })

    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    getCategoryChildrens = async (parentId) => {

        this.setState({ loading: true })
        await Api.get(`/organizationcategories/childrensbyparent/${parentId}`)
            .then(async result => {
                if (result.data.success) {

                    this.setState({ loading: false, resultCategories: result.data.data })
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }


            });
    }

    getAllCategoryOfPortal = async () => {

        this.setState({ loading: true })
        await Api.get(`/organizationcategories/getallcategoryofportal`)
            .then(async result => {
                if (result.data.success) {

                    this.setState({ loading: false, resultCategoriesOfPortal: result.data.data })
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                }


            });
    }


    getImage = async (AttachmentId) => {

        if (AttachmentId != null && AttachmentId != undefined) {
            var url = ""
            await Api.post(`/organizationcategories/attachments`, { AttachmentId })
                .then((result) => {


                    if (result.data) {
                        if (result.data.data && result.data.data.length != undefined && result.data.data.length != 0) {

                            url = result.data.data
                            this.setState({
                                urlImage: url
                            })

                            return result

                        }

                    }

                })
                .catch((err) => {
                    PapiroConsole.log(err);
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            err.response && err.response.data && err.response.data.errors
                                ? err.response.data.errors[0]
                                : this.props.intl.formatMessage({ id: "process.error" }),
                    });


                });

            return url

        }

    }
    handleChange(e, newValue) {


        this.setState({ tabvalue: newValue });

    }

    arraysEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    getUnratedCalleds = async (page = 1) => {

        let idOrganization = null;
        let unratedCalledsList = []

        //let url = this.organizationId != null && this.organizationId > 0 ? `/calleds/classifications/organization/${this.organizationId}/user/${this.userid}/page/${page}` : `/calleds/classifications/all/${this.state.userId}/page/${page}`;
        
        let url = `/calleds/classifications/dapper/organization/${this.organizationId}/user/${this.userid}/page/${page}` ;
        this.setLoading(true)
        await Api.get(url)
            .then((result) => {
                if (result.data.success) {

                    result.data.data.forEach((element) => {
                        if (element != null) {
                            if (element.calledclassification == null) {
                                const exists = unratedCalledsList.some(newItem => newItem.id === element.id);
                                if (!exists) {
                                    unratedCalledsList.push({ ...element, displayassignedinfomode: this.displayassignedinfomode })
                                    this.idCalled = element.id;
                                    this.descriptionCalled = element.description;
                                    idOrganization = element.organizationid;
                                }
                                
                            }
                        }
                    });

                    PapiroConsole.log("unratedCalledsList aqui 1") 
                    PapiroConsole.log(unratedCalledsList)
                    PapiroConsole.log(this.state.unratedCalledsList)
                    //PapiroConsole.log(unratedCalledsList)

                    // const idsUnratedCalledsListState = new Set(this.state.unratedCalledsList.map(item => item.id));
                    // const filteredArray = unratedCalledsList && unratedCalledsList.length && unratedCalledsList.length > 0 ? unratedCalledsList.filter(item => !idsUnratedCalledsListState.has(item.id)) : [] ;

    
                    if(page > 1){
                        unratedCalledsList = [...this.state.unratedCalledsList, ...unratedCalledsList]
                    }
                    
                      
                    
                    this.setState({ unratedCalledsList: [...unratedCalledsList] })
                    if (unratedCalledsList.length !== 0) {
                        this.setState({ modalRatingOpen: unratedCalledsList.length !== 0 }, () => {
                            this.modalRating = <ModalRating
                                data={unratedCalledsList}
                                onClose={() => this.closeModalRating()}
                                requiredratingcalleds={this.requiredratingcalleds}
                                organizationId={this.organizationId}
                                createdUser={this.userid}
                                setLoading={this.setLoading}
                                setMessage={this.setMessage}
                                getFinishedCalleds={this.getUnratedCalleds}
                                showMore={result.data.data.length >= 10}
                                updateList={(newList) => this.setState({ unratedCalledsList: newList })}
                                adm={this.AdmOrganization}
                                originOfSurveyResponse = {1}
                            />
                        });
                    }
                    this.setLoading(false)
                } else {
                    //console.log("=== getItens -> else ===")
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage:
                            result.data &&
                                result.data.response &&
                                result.data.response.data &&
                                result.data.response.data.errors &&
                                result.data.response.data.errors[0]
                                ? result.data.response.data.errors[0]
                                : intl.formatMessage({ id: "process.error" }),
                    });
                    Api.kickoff(result);

                }
            })
            .catch((err) => {
                PapiroConsole.log("=== getItens -> catch ===")
                PapiroConsole.log(err)
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response &&
                            err.response.data &&
                            err.response.data.errors &&
                            err.response.data.errors[0]
                            ? err.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                });
                Api.kickoff(err);

            });
    }

    setLoading = (isLoad) => {
        this.setState({
            loading: isLoad
        })
    }

    setMessage = (open, variant, message) => {
        this.setState({
            openNotification: open,
            notificationVariant: variant,
            notificationMessage: message
        });
    }

    closeModalRating() {
        this.setState({
            modalRatingOpen: false,
            unratedCalledsList: []
        });
    }

    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        const { called } = this.state;

        if (this.state.modalRatingOpen === false) {
            this.modalRating = null;
          }

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }



        return (
            <Container component='main' maxWidth='lg'>
                {this.modalRating}
                {(this.state.informativeText != null && this.state.informativeText.length > 0) && <Accordion expanded={this.state.informativeTextAccordionExpanded} onChange={this.handleChangeAccordion("informative-text")} sx={{
                    width: '30%',
                    position: 'fixed',
                    top: '15%',
                    right: '2%',
                    zIndex: '99999'
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="informative-text-content"
                        id="informative-text-header">
                        <Typography>Informativo</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3} >
                            <Grid container spacing={0}>
                                <Grid item xs={1} sm={1} style={{ backgroundColor: '#f7f2a9' }}></Grid>
                                <Grid item xs={11} sm={11} style={{ padding: '25px 25px 25px 20px' }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.informativeText }} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </AccordionDetails>
                </Accordion>}
                <CssBaseline />

                <div className={classes.paper} style={{ marginTop: 0 }}>
                    <Dialog
                        open={this.state.calledInfoDialogOpen}
                        onClose={this.closeCalledInfoDialog}
                        aria-labelledby="form-delete-filter"
                        maxWidth="md"
                        fullWidth={false}
                        style={{ overflow: 'hidden' }}
                        scroll="body" >
                        <DialogTitle id="form-delete-filter">Chamado criado com sucesso!</DialogTitle>
                        <DialogContent style={{ minWidth: "400px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                        Número do chamado:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" component="span" className={this.props.class}>
                                        {this.state.calledInfoDialogId}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                        Título do chamado:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" component="span" className={this.props.class}>
                                        {this.state.calledInfoDialogTitle}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                        Data de previsão de solução:&nbsp;
                                    </Typography>
                                    <Typography variant="body1" component="span" className={this.props.class}>
                                        {this.state.calledInfoDialogDueDate}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeCalledInfoDialog} color="primary">
                                {intl.formatMessage({ id: "close" })}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container spacing={6} style={{ marginBottom: 15 }}>
                        <Grid item xs={12} sm={4}>
                            <Button variant='outlined' color='primary' onClick={() => {
                                this.backHistory()
                            }
                            }>{<FormattedMessage id="back" />}</Button>
                        </Grid>
                        <Grid item xs={12} sm={8} />
                    </Grid>

                    <Grid container spacing={6} style={{ marginBottom: 15 }}>
                        <Grid item xs={12} sm={12}>
                            <Card className="background-title-search">
                                <CardContent>
                                    <Grid container >
                                        <Grid container >
                                            <Grid item xs={12}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id="portal" />}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: 10 }} >
                                            <Grid item xs={12} >

                                                <SimpleAutoComplete label={intl.formatMessage({ id: "categories" })} options={this.state.resultCategoriesOfPortal} stateName='searchCategories' changeSelect={this.changeValues} />
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={6} style={{ marginBottom: 15 }}>
                        <Grid item xs={12} sm={12}>
                            <Card >
                                <CardContent>
                                    <Grid container >
                                        <Grid item xs={2}>
                                            {this.state.urlImage != null

                                                ?
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                                    src={`${this.state.urlImage}`}

                                                />

                                                : null

                                            }

                                        </Grid>

                                        <Grid item xs={8} style={{ padding: 16 }}>

                                            <Typography gutterBottom variant="h3" component="div">
                                                {this.state.organizationCategory.parentName != "" && this.state.organizationCategory.parentName != null
                                                    ? this.state.organizationCategory.parentName
                                                    : this.state.organizationCategory.name
                                                }
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.organizationCategory.description }} />

                                            </Typography>
                                        </Grid>

                                    </Grid>


                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>



                    {this.state.organizationCategory.canCreateCalled == true && this.state.resultCategories.length == 0 ?


                        <Grid container spacing={2} component="main" >


                            <Grid item xs={4} ></Grid>
                            <Grid item xs={4} align="center">
                                <Avatar className={classes.avatar}>
                                    <QueuePlayNext />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    {<FormattedMessage id="called.new" />}
                                </Typography>

                            </Grid>
                            <Grid item xs={4}></Grid>
                            {(this.state.organizationCategory != null && this.state.organizationCategory.information != null && this.state.organizationCategory.information.length > 0) && <Grid item xs={12} sm={12} style={{ marginBottom: "5px" }}>
                                <Typography variant="h4">
                                    <FormattedMessage id="category.infomation" />
                                </Typography>
                            </Grid>}
                            {(this.state.organizationCategory != null && this.state.organizationCategory.information != null && this.state.organizationCategory.information.length > 0) && <Grid item xs={12} sm={12} >
                                <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold', }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.organizationCategory.information }} />
                                </Typography>
                            </Grid>}

                            <form
                                name="myForm"

                                onSubmit={this.handleSubmit}
                                encType="multipart/form-data"
                            >
                                <Grid container spacing={2} style={{ marginLeft: "1px" }} >
                                    <Grid item xs={12} sm={12} >
                                        <If test={this.state.organizationFieldList && this.state.organizationFieldList.length > 0} >
                                            <Grid item xs={12} sm={12}>
                                                <fieldset style={{
                                                    marginTop: 25,
                                                    marginBottom: 25,
                                                    border: '1px solid #c0c0c0',
                                                    padding: '0 0 15px 0',
                                                    borderLeft: 'none',
                                                    borderRight: 'none'
                                                }}>
                                                    <legend style={{
                                                        paddingTop: 15,

                                                        fontSize: '1.3rem',
                                                        border: '1px solid #c0c0c0',
                                                        borderBottom: 'none',
                                                        borderLeft: 'none',
                                                        borderRight: 'none'
                                                    }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                                                    <Grid container spacing={2} style={{ marginBottom: "25px" }}>
                                                        {this.state.campoTexto}
                                                    </Grid>
                                                </fieldset>
                                            </Grid>
                                        </If>

                                    </Grid>
                                    <Grid item xs={12} sm={12} >
                                        <TextField
                                            autoComplete="fname"
                                            name="title"
                                            variant="outlined"
                                            fullWidth
                                            id="title"
                                            label={intl.formatMessage({ id: "title" })}
                                            value={called.title ? called.title : ""}
                                            onChange={this.handleInputChange}
                                            inputProps={{ maxLength: 250 }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ marginBottom: "25px" }} >

                                        <Typography>
                                            <FormattedMessage id="description" />*
                                        </Typography>
                                        <ReactQuill name="description" value={called.description} style={{ height: 200 }}
                                            modules={this.reactQuillModules}
                                            formats={this.reactQuillFormats}
                                            fullWidth
                                            required
                                            variant="outlined"
                                            id="outlined-multiline-static"
                                            onChange={this.handleChangeEditorDescription} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} mt={3}  >
                                        <Typography variant="h6" gutterBottom >
                                            <FormattedMessage id="attach.your.files" />
                                        </Typography>
                                        <section style={{ width: "100%", marginTop: '8px' }}>
                                            <div className={classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                                                <Dropzone
                                                    onDrop={(acceptedFiles) => {
                                                        this.setState({ files: [...acceptedFiles, ...this.state.files] });
                                                    }}
                                                >
                                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <AttachFileIcon />
                                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                                <FormattedMessage id="drag.and.drop.files" />
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {this.state.files.map((file) => {
                                                    return (
                                                        <div className={classes.dropzoneItem} key={file.name}>
                                                            {file.name}
                                                            <DeleteForever
                                                                className={classes.deleteItem}
                                                                onClick={() => {
                                                                    this.removeItem(file);
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </section>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end', marginBottom: '10px' }}  >
                                            <Grid item xs={12} sm={3} style={{ justifyContent: 'flex-end' }}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                //onClick={this.handleSubmit}
                                                //disabled={this.state.disabledSaveButton}
                                                >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </form>





                        </Grid>


                        :

                        <Grid container spacing={6} style={{ marginTop: 15, marginLeft: "33px" }}>

                            <Tabs value={this.state.tabvalue} onChange={this.handleChange} aria-label="icon label tabs example">
                                <Tab icon={<CategoryIcon />} label="Serviços" />



                            </Tabs>

                            <Grid container spacing={2} style={{ marginTop: 10 }} >


                                {this.state.resultCategories.map((element) => {
                                    return (

                                        <Cardcategories actualElement={this.state.organizationCategory.id} key={element.id} properties={this.props} element={element} maxLength={150} />



                                    );
                                })}


                            </Grid>

                        </Grid>




                    }






                </div>








                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}


                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />
            </Container>
        );
    }
}
CardInformations.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    organizationcategoryportal: state.organizationcategoryportal
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CardInformations))));
CardInformations.contextType = AbilityContext;
