import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, TextField, Grid, Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Switch, Autocomplete } from '@mui/material'
import Api from '../../../services/api'
import * as Service from '../../../services/whitelabelconfig.service'
import { FormattedMessage } from 'react-intl'
import SimpleHelp from "../../../components/help"
import SimpleCheckBox from '../../../components/checkbox/check'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import DialogConfirmationDeleteLoginImage from '../../../components/dialog-confirmation-not-button'
import { PapiroConsole } from '../../../utils/papiroConsole'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as whiteLabelConfigActions from '../../../redux/actions/whitelabelconfigs';
import { CompactPicker } from 'react-color'
import { DeleteForever, AttachFile, Delete, } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import * as FileService from '../../../services/attachment.service';
import SimpleAutoComplete from 'src/components/auto-complete/autocomplete'
import * as AlertService from '../../../services/alert.service'


class WhiteLabelConfig extends Component {

  constructor(props) {
    super(props)

    PapiroConsole.log("=== WhiteLabelConfig ===")
    PapiroConsole.log(this.props)

    const { whitelabelconfigs, userId, hirerId } = this.props

    let itemData = {}
    if (whitelabelconfigs && whitelabelconfigs.whitelabelconfig && whitelabelconfigs.whitelabelconfig.id)
      itemData = whitelabelconfigs.whitelabelconfig

    this.state = {
      whitelabelconfig: {
        id: itemData && itemData.id ? itemData.id : 0,
        applicationname: itemData && itemData.applicationname ? itemData.applicationname : "",
        logintitle: itemData && itemData.logintitle ? itemData.logintitle : "",
        footertext: itemData && itemData.footertext ? itemData.footertext : "",
        showfooterusetermslink: itemData && itemData.showfooterusetermslink != null ? itemData.showfooterusetermslink : true,
        primarycolortheme: itemData && itemData.primarycolortheme ? itemData.primarycolortheme : "",
        secondarycolortheme: itemData && itemData.secondarycolortheme ? itemData.secondarycolortheme : "",
        loginimage: itemData && itemData.loginimage ? itemData.loginimage : null,
        loginimageid: itemData && itemData.loginimageid ? itemData.loginimageid : 0,
        menuimage: itemData && itemData.menuimage ? itemData.menuimage : null,
        menuimageid: itemData && itemData.menuimageid ? itemData.menuimageid : 0,
        externaluserregisterimage: itemData && itemData.externaluserregisterimage ? itemData.externaluserregisterimage : null,
        externaluserregisterimageid: itemData && itemData.externaluserregisterimageid ? itemData.externaluserregisterimageid : 0,
        createduser: userId,
        updateduser: userId,
        hirerid: itemData && itemData.hirerid ? itemData.hirerid : hirerId,
        alertid: null

      },
      deleteLoginImageDialog: false,
      files: [],
      filesMenu: [],
      filesExternalUser: [],
      colorList: ['#000000', '#1351B4', '#1f2626', '#333333', '#4D4D4D', '#666666', '#27271E', '#452f28', '#182825', '#93403B', '#AC4949', '#781113', '#FF0000', '#9F0500', '#6C1A1A', '#D33115', '#C45100', '#091813', '#102505', '#202D1E', '#194D33', '#3E642A', '#0C797D', '#007698', '#2F32DB', '#073763', '#142738', '#01102d', '#050946', '#260749', '#704A72', '#AB149E', '#653294', '#360741', '#3E0E39', '#21041A'],
      alertsList: []
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeColor = this.handleChangeColor.bind(this)
    this.handleChangeColorSecondary = this.handleChangeColorSecondary.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.validateImagesAtachments = this.validateImagesAtachments.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.getFile = this.getFile.bind(this)
    this.getFileName = this.getFileName.bind(this)
    this.changeValues = this.changeValues.bind(this)
  }

  async componentDidMount() {


    await this.getAlertsList()
    // await this.getTemplateMessages()


  }

  async getAlertsList() {
    const { whitelabelconfigs } = this.props;

    var alertid = whitelabelconfigs.whitelabelconfig.alertid;
    let { intl, setLoading, setMessage } = this.props
    setLoading(true)
    var result = await AlertService.getAll();
    //console.log(result.data);
    if (result.success) {

      const initialOption = result.data.find(
        option => option.id === alertid,
      );

      PapiroConsole.log("initialOption")
      PapiroConsole.log(initialOption)
      PapiroConsole.log(result.data)



      this.setState(prevState => ({
        whitelabelconfig: {
          ...prevState.whitelabelconfig,
          alertid: initialOption ? initialOption : null
        },
        alertsList: result.data,

      }));
      setLoading(false)


    } else {
      setLoading(false)
      setMessage(true, 'error', result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  };

  handleChangeColor = (color) => {
    this.setState({ whitelabelconfig: { ...this.state.whitelabelconfig, primarycolortheme: color.hex } });
  }

  handleChangeColorSecondary = (color) => {
    this.setState({ whitelabelconfig: { ...this.state.whitelabelconfig, secondarycolortheme: color.hex } });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      whitelabelconfig: {
        ...prevState.whitelabelconfig,
        [name]: value
      }
    }))
  }

  handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    PapiroConsole.log("=== handleChangeSwitch ===")
    PapiroConsole.log(name)
    PapiroConsole.log(checked)
    PapiroConsole.log(e.target)

    this.setState(prevState => ({
      whitelabelconfig: {
        ...prevState.whitelabelconfig,
        [name]: checked
      }
    }))
  }

  validateImagesAtachments = (files, inputName) => {
    let { intl, setMessage } = this.props
    if (inputName === 'files') {
      if (files.length > 1) {
        this.setState({ files: [] })
        setMessage(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
        return
      }
      else {
        this.setState({ files: [...files, ...this.state.files] })
      }
    } else if (inputName === 'filesMenu') {
      if (files.length > 1) {
        this.setState({ filesMenu: [] })
        setMessage(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
        return
      }
      else {
        this.setState({ filesMenu: [...files, ...this.state.filesMenu] })
      }
    } else if (inputName === 'filesExternalUser') {
      if (files.length > 1) {
        this.setState({ filesExternalUser: [] })
        setMessage(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
        return
      }
      else {
        this.setState({ filesExternalUser: [...files, ...this.state.filesExternalUser] })
      }
    }
  }

  removeItem = (file, inputName) => {
    if (inputName === 'files') {
      let array = [...this.state.files];
      let index = array.indexOf(file)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ files: array });
      }
    } else if (inputName === 'filesMenu') {
      let array = [...this.state.filesMenu];
      let index = array.indexOf(file)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ filesMenu: array });
      }
    } else if (inputName === 'filesExternalUser') {
      let array = [...this.state.filesExternalUser];
      let index = array.indexOf(file)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ filesExternalUser: array });
      }
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    let { intl, setLoading, setMessage } = this.props
    setLoading(true)
    let { whitelabelconfig } = this.state

    let data = new FormData();

    for (let key in whitelabelconfig) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1)
        data.append(key, moment(whitelabelconfig[key]).format('YYYY/MM/DD HH:mm:ss'))
      else if (key.toString().toLowerCase().indexOf('alertid') > -1) {
        data.append(key, this.state.whitelabelconfig[key] ? this.state.whitelabelconfig[key].id : 0);
      }
      else
        data.append(key, whitelabelconfig[key])
    }
    this.state.files.forEach(file => data.append('filesUpload', file));
    this.state.filesMenu.forEach(file => data.append('filesUploadMenuImage', file));
    this.state.filesExternalUser.forEach(file => data.append('filesUploadExternalUser', file));

    if (whitelabelconfig.hirerid === 0)
      whitelabelconfig.hirerid = this.props.hirerId

    let isEdit = whitelabelconfig.id === 0 ? false : true

    let response = whitelabelconfig.id !== 0 ? await Service.editItem(data) : await Service.addItem(data)
    if (response && response.success) {
      setLoading(false)
      setMessage(true, 'success', intl.formatMessage({ id: isEdit ? "edit.success" : "add.success" }))
      if (response.data && response.data.id != null && typeof response.data.id !== 'undefined') {
        this.props.addWhiteLabelConfig(response.data)
        let sessionData = {
          primarycolortheme: response.data.primarycolortheme,
          applicationname: response.data.applicationname,
          footertext: response.data.footertext,
          logintitle: response.data.logintitle,
          showfooterusetermslink: response.data.showfooterusetermslink,
        }

        localStorage.removeItem('whitelabel');
        localStorage.setItem('whitelabel', JSON.stringify(sessionData));

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  getFile = async (hirerid, fileName, filerepositorymethod) => {
    let { intl, setLoading, setMessage } = this.props
    setLoading(true)

    let url = await FileService.getFileByPath(hirerid, fileName, filerepositorymethod);
    if (url && url.data && url.data.data && url.data.success) {
      setLoading(false)
      window.open(`${url.data.data}`, '_blank')
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "file.not.found" }))
    }
  }

  getFileName = (fileName) => {

    let file = fileName != null ? fileName.substring(fileName.lastIndexOf('/') + 1) : "";
    return file;
  }

  deleteLoginImage = async () => {
    const { setLoading, setMessage, intl } = this.props
    setLoading(true)
    const result = await Service.deleteLoginImage(this.state.whitelabelconfig.id)
    if (result.success) {

      const whitelabelconfig = {
        ...this.state.whitelabelconfig,
        loginimage: null,
        loginimageid: null,
      }

      this.setState({ whitelabelconfig: whitelabelconfig })
      this.props.addWhiteLabelConfig(whitelabelconfig)
      setMessage(true, 'success', intl.formatMessage({ id: "delete.sucess" }))

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setMessage(true, 'error', result != null ? result : intl.formatMessage({ id: "process.error" }))
    }
    this.setState({ deleteLoginImageDialog: false })
    setLoading(false)
  }

  changeValues(stateName, value, text = '') {

    this.setState(prevState => ({
      whitelabelconfig: {
        ...prevState.whitelabelconfig,
        [stateName]: value
      }
    }));

  };

  render() {
    const { classes, intl } = this.props
    const { whitelabelconfig } = this.state

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography component='h1' variant='h3'>
              {<FormattedMessage id="whitelabelconfig.header" />}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="applicationname"
              variant="outlined"
              fullWidth
              id="applicationname"
              label={intl.formatMessage({ id: "whitelabelconfig.application.name" })}
              value={whitelabelconfig.applicationname}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="logintitle"
              variant="outlined"
              fullWidth
              id="logintitle"
              label={intl.formatMessage({ id: "whitelabelconfig.login.title" })}
              value={whitelabelconfig.logintitle}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="footertext"
              variant="outlined"
              fullWidth
              id="footertext"
              label={intl.formatMessage({ id: "whitelabelconfig.footer.text" })}
              value={whitelabelconfig.footertext}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={whitelabelconfig.showfooterusetermslink}
                      onChange={this.handleChangeSwitch}
                      name="showfooterusetermslink" />
                  }
                  label={intl.formatMessage({ id: "whitelabelconfig.show.footer.use.terms.link" })} />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography component='h1' variant='h4'>
              {<FormattedMessage id="Alerta ativo no sistema" />}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            {/* <Autocomplete
            label="Alertas"
            getOptionLabel={(option) => option.title}
            options={this.state.alertsList}
            stateName='alertid'
            changeSelect={this.changeValues}
            selected={whitelabelconfig.alertid} */}
            <Autocomplete
              options={this.state.alertsList}
              getOptionLabel={(option) => option.title}
              onChange={(event, value) => this.changeValues('alertid', value)}
              renderInput={(params) => <TextField {...params} label="Alertas" variant="outlined"  />}
              value={whitelabelconfig.alertid}
            />
          </Grid>
          {/* DEIXAR CONFIGURAÇÃO DE CORES COMENTADA ATÉ QUE A REFATORAÇÃO DO TEMA ESTEJA COMPLETA EM TODAS AS TELAS DO SISTEMA
          <Grid item xs={12} sm={6} style={{ marginBottom: '0' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Cor Tema</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <CompactPicker
                color={whitelabelconfig.primarycolortheme}
                colors={this.state.colorList}
                onChangeComplete={ this.handleChangeColor }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom: '0' }}>
            <Grid container spacing={2} style={{ height: '100%', display: 'block' }}>
              <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Demonstração</Typography>
              </Grid>
              <Grid item xs={4} sm={3} style={{ backgroundColor: whitelabelconfig.primarycolortheme.length == 7 ? whitelabelconfig.primarycolortheme : '#3f51b4', height: '70%', marginLeft: 16 }}>
                <Typography component='h1' variant='h3' style={{ marginBottom: '0', color: '#FFF' }}>Texto</Typography>
              </Grid>
              <Grid item xs={8} sm={9} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom: '0' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Cor secundária</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <CompactPicker
                color={whitelabelconfig.secondarycolortheme}
                colors={this.state.colorList}
                onChangeComplete={ this.handleChangeColorSecondary }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom: '0' }}>
            <Grid container spacing={2} style={{ height: '100%', display: 'block' }}>
              <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Demonstração</Typography>
              </Grid>
              <Grid item xs={4} sm={3} style={{ backgroundColor: whitelabelconfig.secondarycolortheme.length == 7 ? whitelabelconfig.secondarycolortheme : '#277afe', height: '70%', marginLeft: 16 }}>
                <Typography component='h1' variant='h3' style={{ marginBottom: '0', color: '#FFF' }}>Texto</Typography>
              </Grid>
              <Grid item xs={8} sm={9} />
            </Grid>
          </Grid>*/}
          <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
            <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagem da tela de login</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <section style={{ width: '100%' }}>
              <div className={classes.dropzoneArea}>
                <Dropzone onDrop={acceptedFiles => this.validateImagesAtachments(acceptedFiles, "files")}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>{<FormattedMessage id="drag.and.drop" />}</p>
                    </div>
                  )}
                </Dropzone>
                {this.state.files.map(file => {
                  return (
                    <div className={classes.dropzoneItem} key={file.name}>
                      {file.name}
                      <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file, "files") }} />
                    </div>
                  )
                })}
              </div>
            </section>
          </Grid>
          {this.state.whitelabelconfig.loginimage != null && this.state.whitelabelconfig.loginimage != "null" && this.state.whitelabelconfig.loginimage != "undefined" && (
            <Grid item xs={12}>
              <label>Anexo</label>
              <ul>
                <li key={this.state.whitelabelconfig.loginimage.path}>
                  <Grid container alignItems='center'>
                    <Grid item sm={.5}>
                      <AttachFile />
                    </Grid>
                    <Grid item sm={10.5}>
                      <Button onClick={() => this.getFile(this.state.whitelabelconfig.loginimage.hirerid, this.state.whitelabelconfig.loginimage.path, this.state.whitelabelconfig.loginimage.filerepositorymethod)}>
                        {this.getFileName(this.state.whitelabelconfig.loginimage.path)}
                      </Button>
                    </Grid>
                    <Grid item sm={1}>
                      <Button color='inherit' onClick={_ => this.setState({ deleteLoginImageDialog: true })}>
                        <Delete />
                      </Button>
                    </Grid>
                  </Grid>
                </li>
              </ul>
            </Grid>
          )}
          {/*<Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
            <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagem de menu</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <section style={{ width: '100%' }}>
              <div className={classes.dropzoneArea}>
                <Dropzone onDrop={acceptedFiles =>  this.validateImagesAtachments(acceptedFiles, "filesMenu")}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>{<FormattedMessage id="drag.and.drop" />}</p>
                  </div>
                  )}
                </Dropzone>
                {this.state.filesMenu.map(file => {
                return (
                  <div className={classes.dropzoneItem} key={file.name}>
                    {file.name}
                    <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file, "filesMenu") }} />
                  </div>
                  )
                })}
              </div>
            </section>
          </Grid>
          {this.state.whitelabelconfig.menuimage != null && this.state.whitelabelconfig.menuimage != "null" && this.state.whitelabelconfig.menuimage != "undefined" && (
          <Grid item xs={12}>
            <label>Anexo</label>
            <ul>
              <li key={this.state.whitelabelconfig.menuimage.path}>
                <AttachFile /> <Button onClick={() => this.getFile(this.state.whitelabelconfig.menuimage.hirerid,this.state.whitelabelconfig.menuimage.path,this.state.whitelabelconfig.menuimage.filerepositorymethod)}>{this.getFileName(this.state.whitelabelconfig.menuimage.path)}</Button>
              </li>
            </ul>
          </Grid>
          )}
          <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
            <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagem do cadastro externo de usuários</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <section style={{ width: '100%' }}>
              <div className={classes.dropzoneArea}>
                <Dropzone onDrop={acceptedFiles =>  this.validateImagesAtachments(acceptedFiles, "filesExternalUser")}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>{<FormattedMessage id="drag.and.drop" />}</p>
                  </div>
                  )}
                </Dropzone>
                {this.state.filesExternalUser.map(file => {
                return (
                  <div className={classes.dropzoneItem} key={file.name}>
                    {file.name}
                    <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file, "filesExternalUser") }} />
                  </div>
                  )
                })}
              </div>
            </section>
          </Grid>
          {this.state.whitelabelconfig.externaluserregisterimage != null && this.state.whitelabelconfig.externaluserregisterimage != "null" && this.state.whitelabelconfig.externaluserregisterimage != "undefined" && (
          <Grid item xs={12}>
            <label>Anexo</label>
            <ul>
              <li key={this.state.whitelabelconfig.externaluserregisterimage.path}>
                <AttachFile /> <Button onClick={() => this.getFile(this.state.whitelabelconfig.externaluserregisterimage.hirerid,this.state.whitelabelconfig.externaluserregisterimage.path,this.state.whitelabelconfig.externaluserregisterimage.filerepositorymethod)}>{this.getFileName(this.state.whitelabelconfig.externaluserregisterimage.path)}</Button>
              </li>
            </ul>
          </Grid>
          )}*/}
          <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
              <Grid item xs={12} sm={4} >
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  olor='primary'
                  className={classes.submit} >
                  {<FormattedMessage id="save" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.deleteLoginImageDialog && (
          <DialogConfirmationDeleteLoginImage
            msgButton={intl.formatMessage({ id: "confirm" })}
            msg={intl.formatMessage({ id: "delete.login.image" })}
            msgDisagree={intl.formatMessage({ id: "cancel" })}
            msgAgree={intl.formatMessage({ id: "yes" })}
            handleConfirmation={_ => this.deleteLoginImage()}
            handleClose={_ => this.setState({ deleteLoginImageDialog: false })}
            classes={classes}
            disabled={false}
            icon={<Delete />}
          />
        )}
      </form>
    )
  }
}

WhiteLabelConfig.propTypes = {
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  whitelabelconfigs: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  whitelabelconfigs: state.whitelabelconfigs
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(whiteLabelConfigActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WhiteLabelConfig)