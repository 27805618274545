import { PapiroConsole } from "src/utils/papiroConsole";

export default function articles(state = [], action) {
  switch (action.type) {
    case "ADD_CURRENT":
      return {
        ...state,
        article: action.article,
      };
    default:
      return state;
  }
}
