import { applyMiddleware, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducers from './reducers';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['headerMenu','userSession', 'calleds','organizations','categories','slas','types',
    'segments', 'reasons','activitys','npssearchs','timeentries','groups','addresses','organizationsettings','originrequests','calledclassifications',
    'users','techinicalteams','techinicalteamusers','techinicalteamorganizationcategories','techinicalteamorganizations',
    'categoryorganizations','roles','permissions','organizationcalendars','assignedcalleds','statuses',
    'configs','calledflows','calledflowstatuses','distributionstrategies','organizationcategories','organizationcategoryportal',
    'organizationslas','organizationslacalleds','scheduleworkhours','slacounttypes','workhours','calledvideocalls', 
    'holidays', 'calledstatuses','materials','organizationdepartaments','organizationmaterials',
    'organizationpatrimonies','patrimonygroups','patrimonymaterials','patrimonysubgroups', 'calledorganizationmaterials',
    'organizationaccountemails', 'organizationfields', 'organizationchecklists','organization', 'knowledgebases', 
    'calledorganizationchecklists', 'calledorganizationfields', 'additionalcalledcosts', 'multihirer', 'defaultactions','securitys',
    'priorities', 'userOrganizations', 'calledclosingstatuses', 'calledlistdatas', 'calledreportconfig', "additionalcalledcostitems", 
    "additionalcalledcostitemtypes", "additionalcalledcostitemreasons", 'organizationfieldreportblocks', 'shippingservicesettings', 
    'richtextarea', 'units', 'whitelabelconfigs', 'calledservicesettings', 'calledservicesettingsorganizationfields', 
    'calledservicesettingsorganizationfieldvalidations', 'calledservicesettingsfileuploads', 'calledservicesettingsfileuploadvalidations', 
    'calledservicesettingsvalidations', 'calledservicesettingscalledfields','accompaniments', 'lastCalledsQueue', 'phoneregistrationrequests', 
    'chats', 'satisfactionsurveys', 'channels', 'customers', 'companies', 'webchats', 'chatbots', 'integrationauths','integrationUserVariables',
    'params', 'chatbotflows', 'integrationservices','quickresponses','feedbackarticles', 'articlessection', 'articles', 'templatemessages', 'breakreasons', 'alerts']
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = applyMiddleware(thunk)( createStore)(persistedReducer, /* preloadedState, */ devToolsEnhancer());

const persistor = persistStore(store);

export { persistor, store };

