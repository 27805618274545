import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  minHeight: 200, // Altura mínima
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 3,
  
};

const buttonStyle = {
  bgcolor: '#3f51b4',
  color: '#fff',
  textTransform: 'none',
  ':hover': {
    bgcolor: '#2c387e',
  },
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  mt: 3, // Margem superior
};

export default function DialogSystemAlert(props) {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const props.handleCloseDialogSystemAlert = () => setOpen(false);

  return (
    <div>
      
      <Modal
       style={{outline:'none'}}
        open={props.open}
        // onClose={props.handleCloseDialogSystemAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}  style={{outline:'none'}}>
          {/* Cabeçalho */}
          <Box sx={headerStyle}  style={{outline:'none'}}>
            <Typography id="modal-modal-title" variant="h5" fontWeight="bold">
              {props.title}
            </Typography>
            <IconButton onClick={props.handleCloseDialogSystemAlert} aria-label="close" >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Conteúdo */}
          <Typography
            id="modal-modal-description"
            sx={{ color: '#333', textAlign: 'justify', flexGrow: 1 }}
            dangerouslySetInnerHTML={{ __html: props.message }}
          >
           
          </Typography>
          {/* Rodapé */}
          <Box sx={footerStyle}>
            <Button variant="contained" sx={buttonStyle} onClick={props.handleCloseDialogSystemAlert}>
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
