import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import ability from './config/ability'
import  ChatHubProdiver  from './contexts/chatHubContext';
import { AbilityContext } from './config/ability-context'
import { PersistGate } from 'redux-persist/integration/react';
import MessageHub2TalkProdiver  from './contexts/MessageHub2TalkContext';

document.getElementById('root').setAttribute('notranslate', true);

ReactDOM.render(
    <AbilityContext.Provider value={ability}>               
            <Provider store={store}>
                <ChatHubProdiver>
                    <MessageHub2TalkProdiver>
                        <PersistGate loading={null} persistor={persistor}>
                            <App />
                        </PersistGate>
                    </MessageHub2TalkProdiver>
                </ChatHubProdiver>
            </Provider>        
    </AbilityContext.Provider>,        
document.getElementById('root'));


