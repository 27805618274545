import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Service from '../../services/segment.service';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import SimpleSelect from "../../components/select/simple";
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import ReactQuill from 'react-quill'; // ES6

const reactQuillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
}

const reactQuillFormats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
]


class AlertRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );


    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      title: '',
      time: "",
      displayLocation: { id: 0, name: '' },
      message: ""
    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      DisplayLocationList: [
        { id: 1, name: "Após o login" },
        { id: 2, name: "Antes do login" },
      ]

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }



  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  validations() {

    let validate = true;

    if (this.state.item.title == null || this.state.item.title == '') {

      validate = false
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É ncessário preencher o campo 'Nome'"
      });
    }
    else if (this.state.item.message == null || this.state.item.message == '') {
      validate = false;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É ncessário preencher o campo 'Mensagem'"
      });
    }
    else if (this.state.item.time == null || this.state.item.time == '') {
      validate = false;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É ncessário preencher o campo 'Tempo de permanência em tela em segundos'"
      });
    }
    else if (this.state.item.displayLocation == null || this.state.item.displayLocation.id == 0) {
      validate = false;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É ncessário preencher o campo 'Local de exibição'"
      });

    }


    return validate;




  };

  handleSubmit = async e => {
    e.preventDefault();


    let validations = this.validations()
    if (validations) {
      this.setState({ loading: true });

      let data = new FormData();

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        }
        else if (key.toString().toLowerCase().indexOf('displaylocation') > -1) {
          data.append(key, this.state.item[key] ? this.state.item[key].id : null);
        }

        else {
          data.append(key, this.state.item[key]);
        }
      }

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.post('/alert', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              item: this.initialState,
              files: []
            });


          }
          else {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });
    }


  };

  handleChangeEditor(value, name) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value || "",
      },
    }));
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField required fullWidth label={intl.formatMessage({ id: "title" })} autoComplete='fname' variant='outlined' name='title' onChange={this.handleInputChange} value={item.title} />
                  </Grid>
                  {/* <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "message" })} autoComplete='fname' variant='outlined' name='message' onChange={this.handleInputChange} value={item.message} required />
                  </Grid> */}

                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="Mensagem" />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <ReactQuill
                      required
                      name="message"
                      value={item.message}
                      style={{ height: "100%" }}
                      modules={reactQuillModules}
                      formats={reactQuillFormats}
                      fullWidth
                      variant="outlined"
                      id="message"
                      onChange={(value) =>
                        this.handleChangeEditor(value, "message")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: '50px' }}>
                    <TextField required fullWidth type="number" label="Tempo de permanência em tela em segundos" autoComplete='fname' variant='outlined' name='time' onChange={this.handleInputChange} value={item.time} inputProps={{
                      min: 0, // Valor mínimo
                      max: 100, // Valor máximo
                      step: 1, // Incrementos
                    }} />
                  </Grid>
                  <Grid item sm={12} md={12} >
                    <SimpleAutoComplete required fullWidth label={`Local de exibição`} options={this.state.DisplayLocationList} stateName='displayLocation' changeSelect={this.changeValues} />
                  </Grid>





                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

AlertRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AlertRegistration)));
