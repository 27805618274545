
import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table/index-report';
import Api from "../../services/api";
import * as CalledService from '../../services/called.service';
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Dialog } from '@mui/material';
import Budget from './components/budget';
import {
    PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import { saveAs } from 'file-saver';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LogoMarca from '../../images/new-logo-cmtech.png';
import html2canvas from 'html2canvas';
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import { PapiroConsole } from "../../utils/papiroConsole";
import ApiReports from "../../services/apireport";
import fileDownload from "js-file-download";

class ChecklistReports extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.getFile = this.getFile.bind(this);
        this.Reload();
        const intl = this.props.intl;
        this.typingTimeout = null;

        const { userSession } = this.props;

        this.isClient = true;
        this.isAdm = false;
        this.ChecklistPie = null;
        this.COLORS = null
        this.arrayChecklist = []
        this.checklistOptions = [
            { id: 1, name: intl.formatMessage({ id: "checklist.report.called.answered" }) },
            { id: 2, name: intl.formatMessage({ id: "checklist.report.called.answered.partial" }) },
            { id: 3, name: intl.formatMessage({ id: "checklist.report.called.answered.none" }) }
        ]

        if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1) {
                this.isAdm = true;
            }
        }
        );
        this.organizationId = null;
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }



        this.state = {
            dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            location: 0,
            slafiltersolution: 0,
            userList: [],
            slafilterservice: 0,
            checklistAttended: 0,
            user: 0,
            activeIndex: 0,
            departament: 0,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            showGrid: false,
            calledsList: [],
            arrayChecklist: [],
            organizationList: [],
            organizationAddressesList: [],
            organizationDepartamentsList: [],
            calledPdfList: [],
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            showHeader: false,
            cloneCalledModalOpen: false,
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getCalledsToPdf = this.getCalledsToPdf.bind(this);
        this.closeCloneCalledModal = this.closeCloneCalledModal.bind(this);
        this.getUserOptions = this.getUserOptions.bind(this);
    }

    closeCloneCalledModal = () => this.setState({ cloneCalledModalOpen: !this.state.cloneCalledModalOpen })

    onActiveIndexEnter = (data, index) => {
        this.setState({ activeIndex: index });
    };
    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} textLength="190" dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`( ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };


    async changeValues(stateName, value, text = '') {
        this.setState({ loading: true });
        if (stateName === 'location' || stateName === 'departament' || stateName == 'checklistAttended') {
            this.setState({ [stateName]: value.id });
        }

        else if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id });
            await this.getOrganizationAddresses(value.id);
            await this.getOrganizationDepartaments(value.id);
            await this.getUserOptions(value.id);



        } else if (stateName === 'user') {
            this.setState({ [stateName]: value.id })
        }

        else {


            this.setState({ [stateName]: value });

        }
        this.setState({ loading: false });


    };

    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);


        }
        return null;
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getCalleds('', page);
    }

    exportPdf() {
        this.getCalledsToPdf();
    }

    async getUserOptions(organizationId) {
        const intl = this.props.intl;
        this.setState({ loading: true });

        const result = await CalledService.getTechnicalUsersByOrganizationId(organizationId);

        if (result.success) {
            if (result.data.length < 1) {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: intl.formatMessage({ id: "called.classification.report.no.technicians" })
                });
                return
            }
            this.setState({ loading: false, userList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1) {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({ id: "called.accompaniment.notification.organization.no.registered.address" })
                    });
                }
            }

            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async getOrganizationDepartaments(organizationId) {
        this.setState({ loading: true });

        var organizationDepartamentsList = null

        const result = await CalledService.getOrganizationDepartaments(organizationId);



        if (result.success) {



            if (result.data) {

                organizationDepartamentsList = result.data
                this.setState({ loading: false, organizationDepartamentsList: organizationDepartamentsList });
            }
            else {
                organizationDepartamentsList = []
            }




        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    onFieldChange(event) {
        // Clears the previously set timer.
        clearTimeout(this.typingTimeout);

        // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
        this.typingTimeout = setTimeout(this.callSearch, 475);


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        this.getCalleds('', this.state.page);
    }

    async getOrganizations() {
        let data = [];
        const { userSession } = this.props
        if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                });
        }
        this.setState({ organizationList: data })
    };

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'Called');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({ id: "operation.notAllowed" }) });
            resolve(); return false;
        }
        Api.delete(`/calledreports/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "called.list.notification.delete.success" }),
                        resultList: []
                    });

                    this.getCalleds();

                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };



    async componentDidMount() {
        this.setState({ loading: true })
        await this.getOrganizations();
        this.setState({ loading: false })


    }
    componentWillReceiveProps(nextProps) {

        //if (nextProps.location.search.indexOf("assignedcalleds") > -1) {
        if (nextProps.location.state === "checklistreports") {
            // do stuffs
            this.getCalleds(this.props.location.state);
        }
        else if (nextProps.location.state === "assignedcalleds") {
            this.getCalleds(nextProps.location.state);
        }
        else if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }
    async getFile() {

        this.setState({ loading: true });
        var url = '/report/checklist/getexcel';

        url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}&dep=${this.state.departament}&check=${this.state.checklistAttended}&user=${this.state.user}`;

        ApiReports.get(url, {
            responseType: 'blob'
        })
            .then(async (response) => {
                this.setState({ loading: false });
                fileDownload(response.data, `checklist_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`);
            });

        const intl = this.props.intl;

        
    }


    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({ id: "yes" })
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({ id: "no" })
        }
        else {
            writing = this.props.intl.formatMessage({ id: "undefined" })
        }

        return writing
    }




    async getCalleds(filter = '', page = 1) {
        const intl = this.props.intl;
        this.setState({ loading: true });

        let url = `/report/checklist/paginate?page=${page}`;
        if (this.isClient) {
            url = `/report/checklist/paginate/${this.state.organizationId}`;
        }
        if (this.state.searchValue != '') {
            url += `&search=${this.state.searchValue}`
        }
        if (filter != '') {
            url = url + '&filter=' + filter;
        }

        url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}&dep=${this.state.departament}&check=${this.state.checklistAttended}&user=${this.state.user}`;

        this.setState({ showGrid: true });

        var writing = ""



        await ApiReports.get(url)
            .then(result => {
                if (result.data.success) {

                    const formattedList = result.data.data.calledsresponse.map(item => {
                        let lastAction = "";
                        let tecteams = "";

                        if (item.organizationcategory != null && item.organizationcategory.techinicalteamorganizationcategories != null && item.organizationcategory.techinicalteamorganizationcategories.length > 0) {

                            item.organizationcategory.techinicalteamorganizationcategories.forEach((element, index) => {

                                if (element.organizationid === item.organization.id && item.organizationcategory.id === element.organizationcategoryid) {
                                    tecteams += element.techinicalteam.name + ' ,'
                                }

                            });

                            tecteams = tecteams.slice(0, -1);
                        }

                        if (item.accompaniments.length > 0) {
                            lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                        } else {
                            lastAction = item.openingdate;
                        }



                        writing = this.getMessageBadUseFlag(item.baduse)



                        return {
                            ...item,
                            baduse: writing,
                            typeName: item.type.name,
                            openingdate: moment(item.openingdate).format('DD/MM/YYYY HH:mm'),
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory.parentname,
                            Organization: item.organization.name,
                            slaAtendimento: item.calledcosts && item.calledcosts.length > 0  ? item.calledcosts[0].slaservice : null,
                            slaSolucao: item.calledcosts && item.calledcosts.length > 0 ? item.calledcosts[0].slasolution : null,
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            lastAction: moment(lastAction).format('DD/MM/YYYY HH:mm'),
                            tecnteams: tecteams,
                            userdepartament: item.assigneduser ? item.assigneduser.organizationdepartament ? item.assigneduser.organizationdepartament.name : "" : "",
                            location: item.address.name
                        }
                    });



                    this.COLORS = ['#0088FE', '#00C49F', '#FFBB28'];


                    this.arrayChecklist = [
                        {
                            "name": intl.formatMessage({ id: "checklist.report.checklist.answered" }),
                            "value": result.data.data.numberofattendedchecklist,


                        },
                        {
                            "name": intl.formatMessage({ id: "checklist.report.checklist.answered.partial" }),
                            "value": result.data.data.numberofparcialattendedchecklist,


                        },
                        {
                            "name": intl.formatMessage({ id: "checklist.report.checklist.answered.none" }),
                            "value": result.data.data.numberofnonattendedchecklist,


                        },

                    ]

                    this.setState({ calledsList: formattedList, loading: false, arrayChecklist: this.arrayChecklist });
                }
                else {


                    PapiroConsole.log("=== Error ===")
                    PapiroConsole.log(result.data)
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                PapiroConsole.log("=== Error ===")
                PapiroConsole.log(err)


                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);

            });
    };

    getMessageExcellBadUseFlag(message, status) {
        const intl = this.props.intl;
        let writing = ""
        if (status === intl.formatMessage({ id: "finished" })) {
            if (message === true) {
                writing = this.props.intl.formatMessage({ id: "yes" })
            }
            else if (message === false) {
                writing = this.props.intl.formatMessage({ id: "no" })
            }
        }
        else {
            writing = this.props.intl.formatMessage({ id: "undefined" })

        }


        return writing
    }


    getCalledsToPdf(filter = '') {

        this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({ id: "routine.in.maintenance" })
        })

        /*this.setState({ loading: true });
        let url = `/calledreports/pdf`;
        if(this.state.searchValue != ''){
            url += `?search=${this.state.searchValue}`
        }
        
        url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}`;

     

        Api.get(url)
            .then(async (result) => {
                if (result.data.success) {

                    await this.setState({showHeader:true})
                    const formattedList = result.data.data.map(item => {
                        let lastAction = "";
                        let tecteams = "";

                        if(item.organizationcategory.techinicalteamorganizationcategories !== null && item.organizationcategory.techinicalteamorganizationcategories.length > 0)
                        item.organizationcategory.techinicalteamorganizationcategories.forEach((element,index)=> {
                            if(element.organizationid === item.organization.id && item.organizationcategory.id === element.organizationcategoryid){
                                tecteams += element.techinicalteam.name + ' ,'
                            }
                            
                        });

                      

                        
                        tecteams = tecteams.slice(0, -1);

                        if(item.accompaniments.length > 0){
                            lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                        } else {
                            lastAction = item.openingdate;
                        }
                        let writing = ""
                        writing = this.getMessageBadUseFlag(item.baduse) 

                        return {
                            ...item,
                            baduse: writing,
                            typeName: item.type.name,
                            openingdate: moment(item.openingdate).format('DD/MM/YYYY HH:mm'),
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory.parentname,
                            Organization: item.organization.name, 
                            slaAtendimento: getSlaAtendimento(item, this.props.userSession)+"%",
                            slaSolucao: getSlaSolucao(item, this.props.userSession)+"%",
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            lastAction: moment(lastAction).format('DD/MM/YYYY HH:mm'),
                            tecnteams:tecteams,
                            userdepartament : item.organizationdepartament? item.organizationdepartament.name: "",
                            location:item.address.name
                        }
                    });
                   
                    const intl = this.props.intl;
                    const doc = new jsPDF("p", "mm", "a4");
                    const header = document.getElementById('header-pdf');
                   
                 
                    let headerCanvas = await html2canvas(header);
                    
                    const headerImg = headerCanvas.toDataURL('image/png');
                    doc.addImage(headerImg, 'PNG', 0, 0);

                    

                    doc.autoTable({
                        body: formattedList,
                        margin: { top: 30, left:1 },
                       
                        bodyStyles: {valign: 'middle'},
                        styles: {overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center'},
                        theme: 'striped',
                        columns: [
                        { header: intl.formatMessage({id:"id"}), dataKey: 'id' },
                        { header: intl.formatMessage({id:"category"}), dataKey: 'OrganizationCategory' },
                        { header: intl.formatMessage({id:"last.move.date"}), dataKey: 'lastAction' },
                        { header: intl.formatMessage({id:"open.date"}), dataKey: 'openingdate' },
                        { header: intl.formatMessage({id:"title"}), dataKey: 'title' },
                        { header: intl.formatMessage({id:"assigned"}), dataKey: 'assignedUser' },
                        { header: intl.formatMessage({id:"menu.statuses"}), dataKey: 'statusName' },
                        { header: intl.formatMessage({id:"organization"}), dataKey: 'Organization' },
                        { header: intl.formatMessage({id:"departament"}), dataKey: 'userdepartament' },
                        { header: intl.formatMessage({id:"menu.techinicalteams"}) , dataKey: 'tecnteams' },
                        { header: intl.formatMessage({id:"location"}), dataKey: 'location' },
                        { header: intl.formatMessage({id:"wasbaduse"}), dataKey: 'baduse' }
                        ],
                        columnStyles: {
                            id: {
                                columnWidth: 9,
                            },
                            OrganizationCategory: {
                                columnWidth: 19,
                            },
                            lastAction: {
                                columnWidth: 20,
                            },
                            openingdate: {
                                columnWidth: 16,
                            },
                            title: {
                                columnWidth: 20,
                            },
                            assignedUser: {
                                columnWidth: 19,
                            },
                            statusName: {
                                columnWidth: 13,
                            },
                            Organization: {
                                columnWidth: 21,
                            },
                            userdepartament: {
                                columnWidth: 20,
                            },
                            tecnteams: {
                                columnWidth: 19,
                            },
                            location: {
                                columnWidth: 18,
                            },
                            baduse: {
                                columnWidth: 12,
                            },
                        },
                    })

                    doc.save("relatorio-chamados.pdf");
                    this.setState({ loading: false,showHeader:false });
                }
                else{
                    
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });                    
                    Api.kickoff(result);
                }
            })
            .catch(err => {

                
                
                
               
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });*/
    };

    handleSubmit = async e => {
        const intl = this.props.intl;
        e.preventDefault();
        this.setState({ loading: true });
        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
        if (dateStart > dateEnd) {

            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
                loading: false
            });

        }
        else {
            await this.getCalleds(this.props.location.state);
        }



    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { activeIndex } = this.state;
        const isLoggedIn = this.state.isLoggedIn;
        const intl = this.props.intl;
        const { classes, userSession, headerMenu, actionsHeaderMenu } = this.props;
        
        let graphic;
        if (this.state.arrayChecklist.length > 0) {
            graphic =

                <Dialog open={this.state.cloneCalledModalOpen} onClose={this.closeCloneCalledModal} aria-labelledby="form-dialog-title" fullWidth
                    maxWidth='sm'
                    justify="center" display="flex">
                    <DialogTitle style={{ color: '#a6a6a6' }} id="form-dialog-title">
                        <span >Chamados x Checklist </span>
                    </DialogTitle>

                    <Divider />




                    <DialogContent style={{ padding: '0px' }}   >

                        <ResponsiveContainer width={"100%"} aspect={1}>

                            <PieChart >
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={this.renderActiveShape}
                                    data={this.state.arrayChecklist}
                                    innerRadius={105}
                                    outerRadius={125}
                                    fill="#8884d8"
                                    onMouseEnter={this.onActiveIndexEnter}
                                >
                                    {this.arrayChecklist.map((entry, index) =>
                                        <Cell key={index} fill={this.COLORS[index]} />
                                    )}
                                </Pie>
                            </PieChart>

                        </ResponsiveContainer>




                        <Grid container  >

                            {this.arrayChecklist.map((entry, index) =>
                                <Grid item xs={12}  >
                                    <Budget titleBudget={entry.name} total={(entry.value)} color={this.COLORS[index]} />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeCloneCalledModal} color="secondary">
                            {intl.formatMessage({ id: "close" })}
                        </Button>

                    </DialogActions>
                </Dialog>
        }



        var link = `/${this.props.match.params.lang}/called-registration`;
        if (this.isClient) {
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }


        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const tableList = () => {
            return (
                <>






                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 20 }} spacing={2} >
                                    <Grid item xs={12} sm={6} md={3}  >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "user" })} options={this.state.userList} stateName='user' changeSelect={this.changeValues} />
                                    </Grid>


                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.organizationDepartamentsList} stateName='departament' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "attended.checklists" })} options={this.checklistOptions} stateName='checklistAttended' changeSelect={this.changeValues} />
                                    </Grid>


                                    {/*
                                    <Grid item xs={12} sm={12} md={3}>
                                    <SimpleAutoComplete label={intl.formatMessage({id:"choose.sla"})} options={this.state.organizationDepartamentsList} stateName='departaments' changeSelect={this.changeValues} />
                                    </Grid>*/}

                                </Grid>
                            </AccordionDetails>
                        </Accordion>


                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'

                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.getFile}

                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.getCalledsToPdf}

                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.closeCloneCalledModal}

                                >
                                    {<FormattedMessage id="display.chart" />}
                                </Button>
                            </Grid>}
                        </Grid>
                    </form>

                    {

                        this.state.showGrid && <>
                            <MyMaterialTable
                                title=""
                                columns={[{ title: intl.formatMessage({ id: "id" }), field: 'id' }, { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' }
                                    , { title: intl.formatMessage({ id: "last.move.date" }), field: 'lastAction' }, { title: intl.formatMessage({ id: "open.date" }), field: 'openingdate' }, { title: intl.formatMessage({ id: "title" }), field: 'title' }, { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' }, { title: intl.formatMessage({ id: "menu.statuses" }), field: 'statusName' }, { title: intl.formatMessage({ id: "organization" }), field: 'Organization' }, { title: intl.formatMessage({ id: "departament" }), field: 'userdepartament' }, { title: intl.formatMessage({ id: "menu.techinicalteams" }), field: 'tecnteams' },
                                { title: intl.formatMessage({ id: "location" }), field: 'location' }, { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' }, { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' }, { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' }]}
                                data={this.state.calledsList}

                            />

                            {/* <Grid container style={{marginBottom:12,marginTop:8}}>
                                                <Grid item xs={12} md={3}>
                                                <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                </Grid>
                </Grid>*/}
                        </>
                    }



                </>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }} >
                        <CssBaseline />
                        {this.state.showHeader &&
                            <Grid container>
                                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }}>
                                    <Card  >

                                        <CardContent>

                                            <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ fontSize: 18 }}> {<FormattedMessage id="called.report.header" />}</span>
                                            <Divider />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>}
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card >
                                    <CardContent className="background-title-search">
                                        <Typography variant="h5">
                                            {<FormattedMessage id="checklist.report.header" />}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>


                        {graphic}





                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

ChecklistReports.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChecklistReports)));
ChecklistReports.contextType = AbilityContext;