import { PapiroConsole } from '../utils/papiroConsole';
import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/tablecustomfilter/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}


export const getByIdAndUserId = async (id,userId) => { 
  let data = []
  await Api.get(`/tablecustomfilter/getbyid/${id}/userid/${userId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getDefaultByUserIdAndTableName = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilterdefault/user/${userId}/table/${tableName}`)
      .then(result => {
        PapiroConsole.log("entrei aqui na requisição 1")
        PapiroConsole.log(result)
          data = result.data
       })
       .catch(err => {
        PapiroConsole.log("entrei aqui na requisição 2")
        PapiroConsole.log(err)
          data = err
  })
  return data
}

export const getFavoritesAndCountByUserIdAndTableName = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/favoritesandcount/user/${userId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getFavoritesAndCountCalledByUserIdAndTableName = async (userId, tableName) => { 
  PapiroConsole.log("entrei aqui nessa função")
  let data = []
  await Api.get(`/tablecustomfilter/favoritesandcountandcalleds/user/${userId}/table/${tableName}`)
      .then(result => {
          PapiroConsole.log("entrei aqui nessa função 2 ")
          data = result.data
       })
       .catch(err => {
          PapiroConsole.log("entrei aqui nessa função 3 ")
          data = err
  })
  return data
}

export const showfiltersincalledlistbyflag = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/showfiltersincalledlistbyflag/user/${userId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getFavoritesByUserIdAndTableName = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/favorites/user/${userId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getCountByUserIdAndTableName = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/count/user/${userId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getAllByUserIdAndTableName = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/user/${userId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getAllByHirerIdAndTableName = async (hirerId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/hirer/${hirerId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getAllByNotUserIdAndIsNotPrivateAndTableName = async (userId, tableName) => { 
  let data = []
  await Api.get(`/tablecustomfilter/notprivate/notuser/${userId}/table/${tableName}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getAllByUserIdAndTableNameSearch = async (formData) => { 
  let data = []
  await Api.post(`/tablecustomfilter/user/search`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/tablecustomfilter`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/tablecustomfilter`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/tablecustomfilter/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}