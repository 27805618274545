import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/alerts.js';
import Footer from '../../components/footer';
import { FormattedMessage,injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';  
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

    class Alert extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;
        const { userSession } = this.props;
        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],

            totalPages:0,
            page:1,
            pageStart:0,
            pageEnd:0,
            count:0,
            searchValue: ''
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e,page) =>{
        this.setState({ page:page });
        this.getItens(page);
    }

    setPagination(count,page){
                            
        let totalPage = Math.ceil (count/10);
        this.setState({ totalPages:totalPage })
        if(count > 0){
            if(page == 1){
                if(count < 10){
                    this.setState({pageStart:1,pageEnd:count});
                } else{
                    this.setState({pageStart:1,pageEnd:10});
                }
            } else{
                let pageStart = ((page - 1) * 10) + 1;
                
                
                
                if(count >= page * 10){
                    this.setState({pageStart:pageStart,pageEnd:page * 10});
                } else{
                    this.setState({pageStart:pageStart,pageEnd:count});
                }
            }
        } else {
            this.setState({totalPages:0,page:1,count:0});
        }
                            
    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page:1 });

    }

    callSearch(){
        if(this.state.searchValue != ''){
            this.getItens(this.state.page);
        }                        
    }

    async clearFields(){
        await   this.setState({searchValue: '', page:1})
        this.getItens(1);
    }


    deleteItem(data, resolve) {
        let abl = this.context.can('Delete', 'Alert');
        if(abl == false){
            const intl = this.props.intl;
            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
            resolve(); return false;
        }

    Api.delete(`/alert/${data.id}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                        resultList: []
                        });

                    this.getItens();
                }
                else{
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });
    

    };


    componentWillReceiveProps(nextProps){
        if(nextProps.location.state === "reload"){
            this.componentDidMount();
        }
    }    

    componentDidMount() {
        this.getItens(1);
    }

    getItens(page = 1) {
        this.setState({ loading: true });
        
        let url = `/alert/paginate?page=${page}`;
        if(this.state.searchValue != ''){
            url += `&search=${this.state.searchValue}`
        }
        this.setState({ loading: true });
                            Api.get(url)
            
                .then(result => {
                    if (result.data.success) {
                        let count = result.data.count;
                        this.setState({count:count});
                        this.setPagination(count,page);
                        const formattedList = result.data.data.map(item => {
                            return {
                                ...item,

                        }
                });
                this.setState({ loading: false, resultList: formattedList });
                }
                else{
                    
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                
                const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
        render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                        <CssBaseline />
                        <Grid container className={classes.mb20}  spacing={0}>

                            <Button
                                variant='contained'
                                color='secondary'
                                size='large'
                                className={classes.button}
                                // disabled={!this.context.can('Add', 'Alert')}
                                startIcon={<AddIcon />}
                    component={Link} {...{ to: `/${this.props.match.params.lang}/alert-registration` }}
                            >
                                {<FormattedMessage id="add" />}
                        </Button>
                        </Grid>
                        <Grid container spacing={6}> 
                        <Grid item xs={12} sm={12}>
                            
                            <Card className="background-title-search">
                                    <CardContent>
                                        
                                        <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item xs={12} md={8}>
                                        <Typography variant="h5">
                                        {<FormattedMessage id="menu.alerts" />}
                                        </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Search />
                                                </Grid>
                                                <Grid item>
                                                    <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({id:"search"})}
                                                    onChange={this.onFieldChange}/>

                                <Button
                                        variant='contained'
                                        color='secondary'
                                        size='large'
                                        className={classes.button}
                                        style={{marginRight:4,marginLeft:8}}
                                        onClick={this.callSearch}
                                    >
                                        <Search />
                                </Button>

                                <Button
                                        variant='contained'
                                        color='primary'
                                        size='large'
                                        onClick={this.clearFields}
                                        className={classes.button}
                            
                                    >
                                        <ClearIcon />
                                </Button>
                                                </Grid>
                                    </Grid>
                                    </CardContent>
                                    </Card>
                            </Grid>
                                            
                        </Grid>
                        <Can I="List" a="Alert">
                        <MyMaterialTable
                        title=""
                        rowClick={(event, rowData) => {
                        // let abl = this.context.can('Edit', 'Alert');
                        // if(abl == false){
                        //     this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                        //     return false;
                        // }
    
                        this.props.addCurrent(rowData);
                        this.props.history.push(`/${this.props.match.params.lang}/alert-edit`);
                            }}
                        columns={[
                            { title: intl.formatMessage({id:"id"}), field: 'id' },
                            { title: intl.formatMessage({id:"title"}), field: 'title' },
                            { title: "Tempo de permanência em tela em segundos", field: 'time' },
                        ]}
                            data={this.state.resultList}
                            deleteItem={this.deleteItem} 
                        />
                        </Can>
                        

                        <Can I='List' a='Alert'>
                            
                        <Grid container style={{marginBottom:12,marginTop:8}}>
                            <Grid item xs={12} md={3}>
                            <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginTop:6}}>
                            {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                            </Grid>
                        </Grid>

                        </Can> 

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}
        

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />   
            </div>
        );
    }
}
Alert.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    actionsHeaderMenu: state.actionsHeaderMenu,
    userSession: state.userSession,
    Alerts: state.Alerts,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Alert)));

Alert.contextType = AbilityContext;               