import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base';
import CalledStatusContent from './index.content';

 
                    import * as componentActions from '../../redux/actions/calledstatuses';
import { PapiroConsole } from 'src/utils/papiroConsole';
                    class CalledStatuses extends Component {

                    constructor(props) {
                        super(props);

                      
                    }

                    componentWillReceiveProps(nextProps){
                        PapiroConsole.log(nextProps)
                        PapiroConsole.log("nextProps")
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                    }

                 
                     render() {
                       

                        return (
                            <Base props={this.props}>
                                   <CalledStatusContent props={this.props} />
                            </Base>                                
                        );
                    }
                }
                CalledStatuses.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledStatuses));
            CalledStatuses.contextType = AbilityContext;
